import NavBar from "components/Navbar/NavBar";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import ChatViewController from "views/Chat/ChatViewController";
import CheckQrCode from "views/CheckQrCode/CheckQrCode";
import DeliveryProgress from "views/DeliveryProgress/DeliveryProgress";
import NewAdvanceDetail from "views/NewAdvanceDetail/NewAdvanceDetail";
import AdvanceInventory from "views/NewAdvanceDetail/inventory/AdvanceInventory";
import OrderAdditionalMaterial from "views/OrderAdditionalMaterial/OrderAdditionalMaterial";
import OrderDebrisRemoval from "views/OrderDebrisRemoval/OrderDebrisRemoval";
import AllServicesAdded from "views/allServicesAdded/AllServicesAdded";
import AuthViewLoader from "views/auth/AuthViewLoader";
import BitaclePhase from "views/bitaclePhase/BitaclePhase";
import NewHome from "views/menu/home/NewHome";
import NewBitacle from "views/newBitacle/NewBitacle";
import { RouteMapController } from "views/route/RouteMapController";
import TimelineRoute from "views/route/TimelineRoute";
import { existUser } from "../services-controller/auth";
import AppView from "../views/app/AppView.jsx";
import AuthViewController from "../views/auth/AuthViewController.jsx";
import CaseEntryInfo from "../views/caseEntryInfo/CaseEntryInfo.jsx";
import MenuController from "../views/menu/MenuController.jsx";
import EditManagementController from "../views/menu/profile/EditManagementController.jsx";
import EditPropertyController from "../views/menu/profile/EditPropertyController.jsx";
import ServiceDetailsController from "../views/menu/services/ServiceDetailsController.jsx";
import NewUnexpectedController from "../views/newUnexpected/NewUnexpectedController.jsx";
import PropertyTypeController from "../views/register/PropertyTypeController.jsx";
import RegisterPropertyController from "../views/register/RegisterPropertyController.jsx";
import RegisterUserController from "../views/register/RegisterUserController.jsx";
import ServiceProviderMenuController from "../views/services-provider/ServiceProviderMenuController.jsx";
import ServiceProviderCotizacionController from "../views/services-provider/ps-cotizacion/ServiceProviderCotizacionController.jsx";
import ServiceProviderCotizacionDetails from "../views/services-provider/ps-cotizacion/ServiceProviderCotizacionDetails.jsx";
import PsNewEntryController from "../views/services-provider/ps-new-entry/PsNewEntryController.jsx";
import BasicInfoServiceProviderController from "../views/services-provider/ps-register/BasicInfoServiceProviderController.jsx";
import ContactInfoServiceProviderController from "../views/services-provider/ps-register/ContactInfoServiceProviderController.jsx";
import ServiceProviderTypeController from "../views/services-provider/ps-register/ServiceProviderTypeController.jsx";
import SpecialityInfoServiceProviderController from "../views/services-provider/ps-register/SpecialityInfoServiceProviderController.jsx";
import PsSeguimiento from "../views/services-provider/ps-seguimiento/PsSeguimiento.jsx";
import ServiceProviderServicesMainController from "../views/services-provider/ps-services/ServiceProviderServicesMainController.jsx";
import StepGuideController from "../views/stepGuide/StepGuideController.jsx";
import NewHomeController from "views/menu/home/NewHomeController";
import {
  ADVANCE_INVENTORY,
  ALL_SERVICES_ADDED,
  APP_PATH,
  AUTH_PATH,
  AUTH_PATH_TOKEN,
  BITACLE_PHASES,
  CASE_BITACLE_ENTRY_INFO,
  CHAT_ENTRY,
  CHECK_QR_CODE,
  CLIENT_SERVICE_DETAILS,
  DELIVERY_PROGRESS,
  MANAGEMENT_EDIT_PATH,
  MENU_OPTION_PATH,
  MENU_PATH,
  NEW_ADVANCE_DETAIL,
  NEW_BITACLE,
  NEW_HOME_PATH,
  ORDER_ADDITIONAL_MATERIAL,
  ORDER_DEBRIS_REMOVAL,
  PRINCIPAL_PATH,
  PROPERTY_EDIT_PATH,
  PROPERTY_FORM_PATH,
  PROPERTY_TYPE_PATH,
  PS_BASE_COTIZACION,
  PS_CASE,
  PS_CASE_BITACLE_ENTRY_VIEW,
  PS_CASE_BITACLE_UNEXPECTED_VIEW,
  PS_CASE_BITACLE_VIEW,
  PS_CONTACT_INFO_FORM_PATH,
  PS_MENU_OPTION_PATH,
  PS_MENU_PATH,
  PS_SPECIALITY_INFO_FORM_PATH,
  ROUTE_MAP,
  ROUTE_TIMELINE,
  SERVICE_PROVIDER_BASIC_INFO_FORM_PATH,
  SERVICE_PROVIDER_TYPE_PATH,
  STEPS_PATH,
  USER_FORM_PATH,
} from "./routes";

const RoutProtected = ({ isValid, component: Component, history, navbar }) => {
  let element = null;

  if (isValid) {
    element = (
      <>
        <Component history={history} />
        {navbar}
      </>
    );
  } else history.push(PRINCIPAL_PATH);

  return element;
};

const Router = ({ history }) => {
  const userExist = existUser();

  return (
    <Switch>
      <Route path={APP_PATH} component={AppView} />

      <Route path={AUTH_PATH}>
        {
          <RoutProtected
            component={AuthViewController}
            isValid={!userExist}
            history={history}
          />
        }
      </Route>

      <Route path={AUTH_PATH_TOKEN} component={AuthViewLoader} />

      <Route path={STEPS_PATH}>
        {
          <RoutProtected
            component={StepGuideController}
            isValid={userExist}
            history={history}
          />
        }
      </Route>

      <Route path={USER_FORM_PATH}>
        {
          <RoutProtected
            component={RegisterUserController}
            isValid={!userExist}
            history={history}
          />
        }
      </Route>

      {/* //customer */}

      <Route path={PROPERTY_TYPE_PATH}>
        {
          <RoutProtected
            component={PropertyTypeController}
            isValid={userExist}
            history={history}
          />
        }
      </Route>

      <Route path={MENU_PATH}>
        {
          <RoutProtected
            component={NewHomeController}
            isValid={userExist}
            history={history}
          />
        }
      </Route>

      <Route path={MENU_OPTION_PATH}>
        {
          <RoutProtected
            component={MenuController}
            isValid={userExist}
            history={history}
          />
        }
      </Route>

      <Route path={PROPERTY_FORM_PATH}>
        {
          <RoutProtected
            component={RegisterPropertyController}
            isValid={userExist}
            history={history}
          />
        }
      </Route>

      <Route path={CLIENT_SERVICE_DETAILS}>
        {
          <RoutProtected
            component={ServiceDetailsController}
            isValid={userExist}
            history={history}
          />
        }
      </Route>

      {/* //service provider */}

      <Route path={PS_BASE_COTIZACION}>
        {
          <RoutProtected
            component={ServiceProviderCotizacionController}
            isValid={userExist}
            history={history}
          />
        }
      </Route>

      {/*case entry info view */}
      <Route path={CASE_BITACLE_ENTRY_INFO}>
        {
          <RoutProtected
            component={CaseEntryInfo}
            isValid={userExist}
            history={history}
          />
        }
      </Route>

      <Route path={CHAT_ENTRY}>
        {
          <RoutProtected
            component={ChatViewController}
            isValid={userExist}
            history={history}
          />
        }
      </Route>

      <Route path={PROPERTY_EDIT_PATH}>
        {
          <RoutProtected
            component={EditPropertyController}
            isValid={userExist}
            history={history}
          />
        }
      </Route>

      <Route path={MANAGEMENT_EDIT_PATH}>
        {
          <RoutProtected
            component={EditManagementController}
            isValid={userExist}
            history={history}
          />
        }
      </Route>

      <Route
        path={SERVICE_PROVIDER_TYPE_PATH}
        component={ServiceProviderTypeController}
      />
      <Route path={SERVICE_PROVIDER_BASIC_INFO_FORM_PATH}>
        <BasicInfoServiceProviderController />
      </Route>
      <Route path={PS_CONTACT_INFO_FORM_PATH}>
        <ContactInfoServiceProviderController />
      </Route>
      <Route path={PS_SPECIALITY_INFO_FORM_PATH}>
        <SpecialityInfoServiceProviderController />
      </Route>
      <Route
        path={PS_MENU_PATH}
        component={ServiceProviderServicesMainController}
      />
      <Route path={PS_MENU_OPTION_PATH}>
        <ServiceProviderMenuController />
      </Route>
      <Route path={PS_CASE}>
        <ServiceProviderCotizacionDetails />
      </Route>
      <Route path={PS_CASE_BITACLE_VIEW} component={PsSeguimiento} />
      <Route
        path={PS_CASE_BITACLE_ENTRY_VIEW}
        component={PsNewEntryController}
      />
      <Route
        path={PS_CASE_BITACLE_UNEXPECTED_VIEW}
        component={NewUnexpectedController}
      />

      <Route path={ALL_SERVICES_ADDED}>
        <RoutProtected
          navbar={<NavBar />}
          component={AllServicesAdded}
          isValid={userExist}
          history={history}
        />
      </Route>

      <Route path={NEW_ADVANCE_DETAIL}>
        <RoutProtected
          component={NewAdvanceDetail}
          isValid={userExist}
          history={history}
        />
      </Route>

      <Route path={CHECK_QR_CODE}>
        <RoutProtected
          navbar={<NavBar />}
          component={CheckQrCode}
          isValid={userExist}
          history={history}
        />
      </Route>

      <Route path={DELIVERY_PROGRESS}>
        <RoutProtected
          component={DeliveryProgress}
          isValid={userExist}
          history={history}
        />
      </Route>

      <Route path={ROUTE_MAP}>
        <RoutProtected
          component={RouteMapController}
          isValid={userExist}
          history={history}
        />
      </Route>

      <Route path={ROUTE_TIMELINE}>
        <RoutProtected
          navbar={<NavBar />}
          component={TimelineRoute}
          isValid={userExist}
          history={history}
        />
      </Route>

      <Route path={ADVANCE_INVENTORY} component={AdvanceInventory}></Route>

      <Route path={ORDER_ADDITIONAL_MATERIAL}>
        <RoutProtected
          component={OrderAdditionalMaterial}
          isValid={userExist}
          history={history}
        />
      </Route>

      <Route path={ORDER_DEBRIS_REMOVAL}>
        <RoutProtected
          component={OrderDebrisRemoval}
          isValid={userExist}
          history={history}
        />
      </Route>


      <Route path={NEW_BITACLE} >
        <RoutProtected isValid={userExist} history={history} component={NewBitacle} />
      </Route>
      <Route path={BITACLE_PHASES}>
        {/* <BitaclePhase /> */}
        <RoutProtected isValid={userExist}  history={history} component={BitaclePhase} />
      </Route>

      <Route path={NEW_HOME_PATH}>
        <RoutProtected isValid={userExist}  history={history} component={NewHomeController} />
      </Route>

      <Redirect from={PRINCIPAL_PATH} to={APP_PATH} />
    </Switch>
  );
};

export default withRouter(Router);
