import { CloseOutlined, DeleteOutlined, PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import useReduxState from 'custom-hooks/useReduxState';
import { useEffect, useState } from "react";
import { ReactMic } from "react-mic";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getEmisorFromUser } from "res/utils";
import { createNewMessage } from "services-controller/http.cleinte.controller";
import { uploadAudioResource } from "services-controller/storage";
import "./SoundRecorder.css";
import { useRef } from "react";

export default function SoundRecorder({ popoverState = [] }) {
  const [isRecording, setIsRecording] = useState(false)
  const [, setIsSendBtnPopoverOpen] = popoverState
  const [recordingData, setRecordingData] = useState({})
  const [{ chatPhaseSelected, chat }] = useReduxState({}, "bitacle")
  const user = useSelector((state) => state.auth.user, shallowEqual)
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const initRecordingRef = useRef()


  const sendAudioMessage = () => {
    (async () => {
      try {
        setIsLoading(true)

        const emisor = getEmisorFromUser(user)
        const audioResourcesResponse = await uploadAudioResource(recordingData?.blob);

        const obj = {
          timestamp: new Date().toISOString(),
          is_valid: false,
          position: chat.length,
          emisor: emisor,
          user: emisor.name,
          message: null,
          admin_read: true,
          admin_read_date: new Date().toISOString(),
          client_read: false,
          client_read_date: "",
          sp_read: false,
          sp_read_date: "",
          type: "audio",
          images: [],
          audio: { ...audioResourcesResponse, type: recordingData?.blob?.type },
        };

        await createNewMessage(id, chatPhaseSelected.key, "admin", obj, emisor)

        setRecordingData({})
        setIsLoading(false)

      } catch (error) {
        setRecordingData({})
        setIsLoading(false)
        console.error("Error sending audio message: ", error);
      }
    })()
  }

  useEffect(() => {
    if (initRecordingRef.current) {
      initRecordingRef.current.click()
    }
    return () => {
      setRecordingData({})
    }
  }, [])

  return (
    <section className="sound-recorder-wrapper">
      <header className="header-sound-recorder">
        <span className="recording-text">
          {isRecording && "Grabando..."}
          {(!isRecording && !recordingData?.blob) && <>Presiona <kbd>▷</kbd> para grabar</>}
        </span>

        <Button
          danger
          icon={<CloseOutlined />}
          size="small"
          className="close-sound-recorder-btn"
          onClick={() => {
            setIsSendBtnPopoverOpen(false);
            setIsRecording(false);
          }}
        >
        </Button>
      </header>

      {!recordingData?.blob && (
        <ReactMic
          record={isRecording}
          className="sound-recorder"
          mimeType="audio/mp3"
          strokeColor="#FD941B"
          backgroundColor="white"
          pause={!isRecording}
          echoCancellation={true}
          onStop={(event) => {
            setRecordingData(event)
          }}
          visualSetting="frequencyBars"
        />
      )}

      {!isRecording && recordingData?.blob && (
        <div className="preview-recording-wrapper">
          <audio controls>
            <source
              src={recordingData?.blobURL}
              type={recordingData?.blob?.type}
            />
          </audio>

          <Button
            size="default"
            icon={<DeleteOutlined />}
            disabled={isRecording}
            danger
            className="clear-recording-btn"
            onClick={() => {
              setRecordingData({})
            }}
          >
          </Button>
        </div>
      )}

      <div className="media-control-wrapper">
        {!recordingData?.blob && (
          <>
            <Button
              ref={initRecordingRef}
              size="default"
              icon={<PlayCircleOutlined />}
              disabled={isRecording}
              onClick={() => {
                setIsRecording(true)
              }}
            >
            </Button>

            <Button
              size="default"
              icon={<PauseCircleOutlined />}
              onClick={() => {
                setIsRecording(false)
              }}
            >
            </Button>
          </>
        )}

        {(recordingData?.blob) && (
          <Button
            size="default"
            loading={isLoading}
            onClick={() => {
              setIsRecording(false)
              sendAudioMessage()
            }}
          >
            Enviar
          </Button>
        )}
      </div>
    </section>
  )
}
