import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Image, Popover, Upload } from "antd";
import useReduxState from "custom-hooks/useReduxState";
import { useCallback, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getEmisorFromUser } from "res/utils";
import { createNewMessage } from "services-controller/http.cleinte.controller";
import { uploadMultipleEvidenceResources } from "services-controller/storage";
import "./FilerSelector.css";
import { useRef } from "react";

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default function FileSelector({ open, setOpen }) {
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [{ chatPhaseSelected, chat }] = useReduxState({}, "bitacle")
  const user = useSelector((state) => state.auth.user, shallowEqual)
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const uploadRef = useRef(null)

  const triggerUpload = () => {
    if (uploadRef.current) {
      const inputElement = uploadRef.current
      inputElement?.click();
    }
  };

  const handlePreview = useCallback((file) => {
    (async () => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
    })()
  }, [])

  useEffect(() => {
    if (fileList.length === 0) {
      triggerUpload()
    }
    return () => {
      setFileList([])
    }

  }, [open])

  const sendResourceMessage = () => {
    (async () => {
      try {
        setIsLoading(true)

        const parsedFileList = fileList.map(file => {
          let fileType = file?.type

          if (file.type.includes("image")) {
            fileType = "image"
          }

          if (file.type.includes("pdf")) {
            fileType = "pdf"
          }
          const blobUrl = URL.createObjectURL(file.originFileObj);
          return { ...file, url: blobUrl, unload: true, type: fileType }
        })

        const resorucesLink = await uploadMultipleEvidenceResources(parsedFileList);
        const emisor = getEmisorFromUser(user)
        const obj = {
          timestamp: new Date().toISOString(),
          is_valid: false,
          position: chat.length,
          emisor: emisor,
          user: emisor.name,
          message: null,
          admin_read: true,
          admin_read_date: new Date().toISOString(),
          client_read: false,
          client_read_date: "",
          sp_read: false,
          sp_read_date: "",
          type: "file",
          images: [],
          files: resorucesLink,
        };

        const userType = user?.register_type === 200 ? 'sp' : "client"
        await createNewMessage(id, chatPhaseSelected.key, userType, obj, emisor)

        setFileList([])
        setIsLoading(false)
        setOpen(false)

      } catch (error) {
        setFileList([])
        setIsLoading(false)
        console.error("Error sending file message: ", error);
      }
    })()
  }

  return (
    <Popover
      placement="bottomRight"
      open={previewImage}
      rootClassName="file-preview-popover"
      content={
        <div className="file-selector-preview-wrapper">
          <Button
            className="close-file-preview-btn"
            onClick={() => setPreviewImage(false)}
            icon={<CloseOutlined />}
            size="small"
            danger
          >
          </Button>

          <Image
            src={previewImage}
            className="file-selector-preview"
            alt="Previsualizador de imagén"
          />
        </div>
      }
    >
      <section className="file-selector-wrapper">
        <Button
          danger
          icon={<CloseOutlined />}
          size="small"
          className="close-file-upload-btn"
          onClick={() => {
            setPreviewImage(false)
            setOpen(false)
          }}
        >
        </Button>

        <Upload
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={(value) => {
            const { fileList } = value

            setFileList(fileList)

            setTimeout(() => {
              const previewListElement = document.querySelector(".file-selector-upload>.ant-upload-list")
              previewListElement?.scrollTo(0, previewListElement?.scrollHeight);
            }, 300);
          }}
          rootClassName="file-selector-upload"
          beforeUpload={() => false}
          multiple
          openFileDialogOnClick={true}
        >
          <Button
            className="upload-btn"
            type="button"
            ref={uploadRef}
          >
            <PlusOutlined />
            Subir archivo
          </Button>
        </Upload>

        <Button
          type="primary"
          className="send-file-btn"
          disabled={!fileList.length}
          loading={isLoading}
          onClick={() => {
            sendResourceMessage()
          }}
        >
          Enviar archivo(s)
        </Button>
      </section>
    </Popover>
  );
}
