import React from 'react';
import { moneyFormat, timeAgo } from 'res/utils'
import { Avatar, Button, Dropdown, Image, Progress, message as MessageApi } from 'antd'
import userIconUrl from "assets/icons/svg/user.svg"
import PdfPreview from "components/atoms/pdfPreview/PdfPreview"

import { ReactComponent as DoubleCheckIcon } from "assets/icons/svg/double-check.svg?react"
import { ReactComponent as ChecksGray } from "assets/icons/svg/checks-gray.svg?react"
import { useRef } from 'react';
import { useEffect } from 'react';

const replaceNameTagStyle = (message, tags) => {
  let finalMessage = message
  Object.entries(tags).forEach(([, tagged]) => {
    const { name } = tagged

    finalMessage = finalMessage.replace("@" + name, `<span class="tagged-name">${"@" + name}</span>`)
  })

  return finalMessage
}

const formatMessage = (message, tags) => {
  let finalMessage = message
  //replace links
  finalMessage = finalMessage.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>')

  //replace tags

  finalMessage = tags ? replaceNameTagStyle(finalMessage, tags) : finalMessage
  return finalMessage
}

const Message = ({ item, index, array, user, allOpenOptions }) => {
  const userId = user?.id
  const timer = useRef(null);
  const isLongPress = useRef(false);
  const [openOptions, setOpenOptions] = React.useState(false);


  const handlePointerDown = () => {
    isLongPress.current = false;
    timer.current = setTimeout(() => {
      isLongPress.current = true;
      setOpenOptions((prev) => !prev);
    }, 500);
  };

  const handlePointerUp = () => {
    clearTimeout(timer.current);

  };

  const handlePointerLeave = () => {
    clearTimeout(timer.current);
  };

  //copy to clipboard
  const handleCopy = () => {
    if (item.type === "file") {
      console.log(item.files);

      navigator.clipboard.writeText(item.files[0].url);
    } else {

      navigator.clipboard.writeText(item.message ?? item.detail);
    }

    MessageApi.success({
      content: "Mensaje copiado al portapapeles",
      duration: 2,
      style: {
        marginTop: "35%",
      },
    });
  };



  let { message, timestamp, type, detail, images, percent, total, title, emisor, audio, files, sp_read, tags } = item
  const prevItem = array?.[index - 1]
  const nextItem = array?.[index + 1]
  const prevSame = prevItem?.emisor?.id === emisor?.id
  const nextSame = nextItem?.emisor?.id === emisor?.id
  const auxType = type ?? "message"

  const prevMinutes = Math.abs(new Date(prevItem?.timestamp) - new Date(timestamp)) / 1000 / 60
  const prevMsgLate = prevMinutes > 30

  const nextMinutes = Math.abs(new Date(nextItem?.timestamp) - new Date(timestamp)) / 1000 / 60
  const nextMsgLate = Number.isNaN(nextMinutes) ? true : nextMinutes > 30

  const isOtherUser = emisor?.id !== user?.id
  message = formatMessage(message ?? '', tags?.tags ?? false)

  const MessageDropdownItems = [
    {
      key: "copy",
      label: <div style={{ width: "100%", height: "100%" }} onClick={() => { setOpenOptions(false); handleCopy() }}>Copiar</div>,

    },
    {
      type: 'divider',
    },

    // ...(isOtherUser
    //   ? [
    //     {
    //       key: "mark-as-read",
    //       label: (
    //         <span
    //           onClick={() => {
    //             setOpenOptions(false);
    //           }}
    //         >
    //           Marcar como leído
    //         </span>
    //       ),
    //     },
    //   ]
    //   : []),
    // {
    //   key: "no-action",
    //   label: <span onClick={() => { setOpenOptions(false) }}>Cancelar</span>,
    // },
  ];


  useEffect(() => {

    setOpenOptions(false);
  }, [allOpenOptions])

  return (
    <article
      key={index}
      id={`message-${item.position}`}
      data-key={item.position}
      className={
        [
          "chat-message",
          isOtherUser && "is-other-user",
          prevSame && "prev-same-user",
          nextSame && "next-same-user",
          prevMsgLate && "prev-msg-late",
        ]
          .filter(Boolean)
          .join(" ")
      }
      data-type={auxType}
    >
      <Dropdown
        open={openOptions}
        menu={{ items: MessageDropdownItems }}
      >

        <section className="message-wrapper"

          onPointerDown={handlePointerDown}
          onPointerUp={handlePointerUp}
          onPointerLeave={handlePointerLeave}
        >
          {title && (<h1 className="header-title">{title}</h1>)}

          {type === "message" && (
            <p className="message-paragraph" dangerouslySetInnerHTML={{ __html: message }}>

            </p>
          )}

          {type === "advance" && (
            <Progress
              percent={(percent * 100)}
              size={[240, 4]}
              className="special-message-progress"
            />
          )}

          {type === "unforeseen" && (
            <span>Tiempo aproximado...</span>
          )}

          {(type === "collection-bill" || type === "payment-invoice") && (
            <span>
              {type === "collection-bill" && "Total a pagar"}
              {type === "payment-invoice" && "Total pagado"}
              {" "}
              <strong>{moneyFormat({ value: total })}</strong>
            </span>
          )}

          {type === "collection-bill" && (
            <footer className="special-message-footer">
              <Button>Ya pague</Button>
              <Button>Pagar</Button>
            </footer>
          )}

          {type === "activity" && (
            <div className="activity-message-wrapper">
              <p dangerouslySetInnerHTML={{ __html: formatMessage(detail ?? '', tags?.tags ?? false) }}></p>
              <div className="activity-resources-wrapper">
                {images?.length && images.map((url, index) => {
                  const isPdf = url.includes(".pdf");

                  if (isPdf) {
                    return (
                      <PdfPreview
                        key={index}
                        title="Evidencia"
                        pdfUrl={url}
                      />
                    )
                  }

                  return (
                    <Image
                      key={index}
                      src={url}
                      width="auto"
                      height="auto"
                    />
                  )
                })}
              </div>
            </div>
          )}

          {type === "audio" && (
            <audio
              controls
              className="audio-message-wrapper"
            >
              <source
                src={audio?.url}
                type={audio?.type.includes("audio/") ? audio?.type : "audio/mp3"}
              />
            </audio>
          )}

          {type === "file" && (
            <div className="file-message-wrapper">
              <p className="message-paragraph" dangerouslySetInnerHTML={{ __html: message }}>

              </p>
              {files?.length && files.map((file, index) => {
                const { url, name } = file
                const isPdf = url.includes(".pdf");

                if (isPdf) {
                  return (
                    <PdfPreview
                      key={index}
                      title={name}
                      pdfUrl={url}
                    />
                  )
                }

                return (
                  <Image
                    key={index}
                    src={url}
                    width="auto"
                    height="auto"
                  />
                )
              })}
            </div>
          )}


        </section>
      </Dropdown>

      {
        (
          (isOtherUser && nextMsgLate) ||
          (isOtherUser && !nextSame) ||
          (!nextSame) ||
          (nextSame && nextMsgLate)
        ) && (
          <footer className="chat-message-footer">
            {(
              (isOtherUser && nextMsgLate) ||
              (isOtherUser && !nextSame)
            ) && (
                <div className="footer-details">
                  <Avatar
                    size={32}
                    src={emisor?.thumbnail || userIconUrl}
                    alt={`Avatar de ${emisor?.name}`}
                    className='emisor-avatar'
                  />

                  <span className='emisor'>{emisor?.name}</span>
                </div>
              )}

            {(
              (!nextSame) ||
              (nextSame && nextMsgLate)
            ) && (
                <time dateTime={timestamp} className="footer-date">
                  {timeAgo(timestamp)}
                </time>
              )}

            {
              emisor?.id === userId &&
              <span>
                {sp_read ?
                  <DoubleCheckIcon className="sp-read" />
                  :
                  <ChecksGray className="sp-read" />
                }

              </span>
            }


          </footer>
        )
      }
    </article>


  )


};

export default Message;