import { updateService } from "services-controller/http.cleinte.controller";

export async function completeService(service, reload) {
  const obj = {
    ...service,
    state: {
      ...service.state,
      stage: 6,
    },
  };

  await updateService(obj.unique_key, obj);
  reload();
}
