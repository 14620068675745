import {
  ADMIN_ASISTANT,
  ADMIN_COORDINATOR,
  APARTMENT,
  ARCHITECT_DESIGN,
  ASSISTANT_TYPE_PATH,
  CARPENTRY_ASSISTANT,
  CARPENTRY_COORDINATOR,
  //NEW ROLES
  CARPENTRY_LEADER,
  CFO,
  COMERCIAL_ASISTANT,
  COMERCIAL_COORDINATOR,
  COMERCIAL_DIRECTOR,
  COMPANY,
  COMPANY_PERSONAL,
  CONDOMINIUM,
  COO,
  CSO,
  DESIGN_AUXILIARY,
  DESIGN_COORDINATOR,
  DOCUMENTATION_ASISTANT,
  HORIZONTAL_HOUSE,
  HORIZONTAL_PROPERTY,
  HOUSE,
  HOUSE_PERSONAL,
  HUMAN_RESOURCES,
  INSURANCE,
  INSURANCE_PERSONAL,
  LOCAL,
  MARKETING_COORDINATOR,
  NEW_PERSONAL,
  OFFICE,
  OFFICIAL_TYPE_PATH,
  OPERATION_COORDINATOR,
  OTHER_PROPERTY,
  OTHER_USER,
  PARTICULAR,
  PLANEATION_ASISTANT,
  PLANEATION_AUXILIARY,
  PLANEATION_COORDINATOR,
  POST_SOLD_AUXILIARY,
  PROPERTY_PERSONAL,
  REAL_STATE,
  REAL_STATE_PERSONAL,
  RESIDENT,
  SUPERVISOR_TYPE_PATH,
  WAREHOUSE,
} from "../router/routes";
import {
  PRIORITY_HIGH,
  PRIORITY_MEDIUM,
  PRIORITY_PRIORITY,
} from "./priority.type";

import {
  CARPENTRY_COORDINATOR_TYPE,
  COMPANY_TYPE,
  CUSTOMER,
  HELPER_TYPE,
  HORIZONTAL_HOUSE_TYPE_SIMPLE,
  HORIZONTAL_PROPERTY_TYPE_SIMPLE,
  INSURANCE_TYPE,
  OFICIAL_TYPE,
  OTHER_USER_TYPE,
  PARTICULAR_TYPE,
  REAL_STATE_TYPE,
  SUPERVISOR_TYPE,
} from "./register.type";

import {
  ADMIN_ASISTANT_CODE,
  ADMIN_COORDINATOR_CODE,
  APARTMENT_CODE,
  ARCHITECT_DESIGN_CODE,
  CARPENTRY_ASSISTANT_CODE,
  CARPENTRY_COORDINATOR_CODE,
  CARPENTRY_LEADER_CODE,
  CFO_CODE,
  COMERCIAL_ASISTANT_CODE,
  COMERCIAL_COORDINATOR_CODE,
  COMERCIAL_DIRECTOR_CODE,
  COMPANY_CODE,
  COMPANY_PERSONAL_CODE,
  //NEW ROLES
  COO_CODE,
  CSO_CODE,
  CUSTOMER_CODE,
  DESIGN_AUXILIARY_CODE,
  DESIGN_COORDINATOR_CODE,
  DOCUMENTATION_ASISTANT_CODE,
  EMPLPOYEE_CODE,
  HORIZONTAL_HOUSE_CODE,
  HORIZONTAL_PROPERTY_CODE,
  HOUSE_CODE,
  HOUSE_CONDOMINIUM_CODE,
  HOUSE_PERSONAL_CODE,
  HUMAN_RESOURCES_CODE,
  INSURANCE_CODE,
  INSURANCE_PERSONAL_CODE,
  LOCAL_CODE,
  MARKETING_COORDINATOR_CODE,
  NEW_PERSONAL_PERSONAL_CODE,
  OFFICE_CODE,
  OPERATION_COORDINATOR_CODE,
  OTHER_PROPERTY_CODE,
  OTHER_USER_CODE,
  PARTICULAR_CODE,
  PLANEATION_ASISTANT_CODE,
  PLANEATION_AUXILIARY_CODE,
  PLANEATION_COORDINATOR_CODE,
  POST_SOLD_AUXILIARY_CODE,
  PROPERTY_PERSONAL_CODE,
  REAL_STATE_CODE,
  REAL_STATE_PERSONAL_CODE,
  RESIDENT_CODE,
  SERVICE_PROVIDER_ASSITANT_CODE,
  SERVICE_PROVIDER_CODE,
  SERVICE_PROVIDER_OFFICIAL_CODE,
  SERVICE_PROVIDER_SUPERVISOR_CODE,
  WAREHOSE_CODE,
} from "../router/codes.type.properties";

import APTO_ICON_SVG from "../assets/icons/svg/apartamento.svg";
import WAREHOUSE_ICON_SVG from "../assets/icons/svg/bodega.svg";
import CASA_ICON_SVG from "../assets/icons/svg/casa.svg";
import CONDOMINIO_ICON_SVG from "../assets/icons/svg/condominio.svg";
import LOCAL_ICON_SVG from "../assets/icons/svg/local-comercial.svg";
import OFFICE_ICON_SVG from "../assets/icons/svg/oficina.svg";
import OTROS_ICON_SVG from "../assets/icons/svg/otros.svg";

const ERROR_CODE = 404;
const ERROR_NAME = "Not Found";
const ERROR_ROUTE = "/no-found";

const SERVICE_PROVIDER_SUPERVISOR = "Supervisor";
const SERVICE_PROVIDER_OFFICIAL = "Oficial";
const SERVICE_PROVIDER_ASSITANT = "Asistente";

const REQUESTED_NAME = "Solicitado";
const TO_BE_APPROVED_NAME = "Por Aprobar";
const APPROVED_NAME = "Aprobado";
const IN_DESING_NAME = "En Diseño";
const REJECTED_NAME = "Rechazado";
const RESCHEDULE_NAME = "En Reajuste";
const IN_PROGRESS_NAME = "En Obra";
const DONE_NAME = "Completado";
const WARRANTY_NAME = "Garantía";
const QUOTATION_IN_PROGRESS_NAME = "Cotizandose";
const READY_TO_EXECUTE_NAME = "En Planeación";
const PAUSED_NAME = "En Pausa";
const WAITING_PAYMENT_NAME = "Por Pagar";
const CANCEL_NAME = "Cancelado";

const REQUESTED = 0; //Solicitado
const TO_BE_APPROVED = 1; //Por aprobar
const APPROVED = 2; //Aprobado
const IN_DESING = 9; //En diseño
const REJECTED = 3; //Rechazado
const RECONFIG = 4; //en Reajuste
const IN_PROGRESS = 5; // En curso
const DONE = 6; //Completado
const WARRANTY = 7; //Garantía
const QUOTATION_IN_PROGRESS = 8; //Cotizandose
const IN_PLANEATION = 10; //En planeación
const PAUSED = 11; //En pausa
const WAITING_PAYMENT = 12; //Por pagar
const CANCEL = 13; //Cancelado

const REQUESTED_COLOR = "state-requested";
const TO_BE_APPROVED_COLOR = "state-to-be-approved";
const APPROVED_COLOR = "state-approved";
const REJECTED_COLOR = "state-rejected";
const RESCHEDULE_COLOR = "state-reschedule";
const IN_PROGRESS_COLOR = "state-in-progress";
const DONE_COLOR = "state-done";
const WARRANTY_COLOR = "state-warranty";
const QUOTATION_IN_PROGRESS_COLOR = "state-quotation-progress";
const QUOTATION_COLOR = "state-quotation";
const READY_TO_EXECUTE_COLOR = "state-ready-execute";
const PAUSED_COLOR = "state-pause";
const WAITING_PAYMENT_COLOR = "state-waiting-payment";
const CANCEL_COLOR = "state-cancel";

const DESIGN_PHASE_COLOR = "#F8BBD0";
const PLANEATION_PHASE_COLOR = "#D1C4E9";
const OPERATION_PHASE_COLOR = "#CFD8DC";

const DESIGN_PHASE_NAME = "Design";
const PLANEATION_PHASE_NAME = "Planeation";
const OPERATION_PHASE_NAME = "Operation";
const OPERATION_PHASE_NAME_SPANISH = "Operaciones";
const DESIGN_PHASE_NAME_SPANISH = "Diseño";
const PLANEATION_PHASE_NAME_SPANISH = "Planeación";

//
const convertTypeRegisterToManagementCode = (value) => {
  switch (value) {
    case COMPANY_CODE:
      return COMPANY_PERSONAL_CODE;
    case PARTICULAR_CODE:
      return HOUSE_PERSONAL_CODE;
    case REAL_STATE_CODE:
      return REAL_STATE_PERSONAL_CODE;
    case INSURANCE_CODE:
      return INSURANCE_PERSONAL_CODE;
    case HORIZONTAL_HOUSE_CODE:
      return PROPERTY_PERSONAL_CODE;
    case HORIZONTAL_PROPERTY_CODE:
      return PROPERTY_PERSONAL_CODE;
    case OTHER_USER_CODE:
      return NEW_PERSONAL_PERSONAL_CODE;
    default:
      return ERROR_CODE;
  }
};

//
const convertTypeRegisterToNames = (value) => {
  switch (value) {
    case COMPANY_CODE:
      return COMPANY_TYPE;
    case PARTICULAR_CODE:
      return PARTICULAR_TYPE;
    case REAL_STATE_CODE:
      return REAL_STATE_TYPE;
    case INSURANCE_CODE:
      return INSURANCE_TYPE;
    case HORIZONTAL_HOUSE_CODE:
      return HORIZONTAL_HOUSE_TYPE_SIMPLE;
    case HORIZONTAL_PROPERTY_CODE:
      return HORIZONTAL_PROPERTY_TYPE_SIMPLE;
    case OTHER_USER_CODE:
      return OTHER_USER_TYPE;
    case SERVICE_PROVIDER_SUPERVISOR_CODE:
      return SUPERVISOR_TYPE;
    case SERVICE_PROVIDER_OFFICIAL_CODE:
      return OFICIAL_TYPE;
    case SERVICE_PROVIDER_ASSITANT_CODE:
      return HELPER_TYPE;
    case OPERATION_COORDINATOR_CODE:
      return CARPENTRY_COORDINATOR_TYPE;
    default:
      return ERROR_CODE;
  }
};

//
const convertRegisterTypeByRouterToCode = (value) => {
  switch (value) {
    case CUSTOMER:
      return CUSTOMER_CODE;

    //OPERATION
    case COO:
      return COO_CODE;
    case OPERATION_COORDINATOR:
      return OPERATION_COORDINATOR_CODE;
    case RESIDENT:
      return RESIDENT_CODE;
    case SUPERVISOR_TYPE_PATH:
      return SERVICE_PROVIDER_SUPERVISOR_CODE;
    case OFFICIAL_TYPE_PATH:
      return SERVICE_PROVIDER_OFFICIAL_CODE;
    case ASSISTANT_TYPE_PATH:
      return SERVICE_PROVIDER_ASSITANT_CODE;
    case CARPENTRY_COORDINATOR:
      return CARPENTRY_COORDINATOR_CODE;
    case CARPENTRY_LEADER:
      return CARPENTRY_LEADER_CODE;
    case CARPENTRY_ASSISTANT:
      return CARPENTRY_ASSISTANT_CODE;

    //ADMINISTRATION
    case CFO:
      return CFO_CODE;
    case ADMIN_COORDINATOR:
      return ADMIN_COORDINATOR_CODE;
    case ADMIN_ASISTANT:
      return ADMIN_ASISTANT_CODE;
    case HUMAN_RESOURCES:
      return HUMAN_RESOURCES_CODE;

    //PLANEATION
    case CSO:
      return CSO_CODE;
    case PLANEATION_COORDINATOR:
      return PLANEATION_COORDINATOR_CODE;
    case PLANEATION_ASISTANT:
      return PLANEATION_ASISTANT_CODE;
    case DOCUMENTATION_ASISTANT:
      return DOCUMENTATION_ASISTANT_CODE;
    case PLANEATION_AUXILIARY:
      return PLANEATION_AUXILIARY_CODE;
    case POST_SOLD_AUXILIARY:
      return POST_SOLD_AUXILIARY_CODE;

    case DESIGN_COORDINATOR:
      return DESIGN_COORDINATOR_CODE;
    case ARCHITECT_DESIGN:
      return ARCHITECT_DESIGN_CODE;
    case DESIGN_AUXILIARY:
      return DESIGN_AUXILIARY_CODE;

    case COMERCIAL_DIRECTOR:
      return COMERCIAL_DIRECTOR_CODE;
    case COMERCIAL_COORDINATOR:
      return COMERCIAL_COORDINATOR_CODE;
    case COMERCIAL_ASISTANT:
      return COMERCIAL_ASISTANT_CODE;
    case MARKETING_COORDINATOR:
      return MARKETING_COORDINATOR_CODE;

    default:
      return ERROR_CODE;
  }
};
const convertUserTypeToRegisterTypeCode = (value) => {
  switch (value) {
    case RESIDENT:
      return SERVICE_PROVIDER_CODE;
    case SUPERVISOR_TYPE_PATH:
      return SERVICE_PROVIDER_CODE;
    case OFFICIAL_TYPE_PATH:
      return SERVICE_PROVIDER_CODE;
    case ASSISTANT_TYPE_PATH:
      return SERVICE_PROVIDER_CODE;
    default:
      return EMPLPOYEE_CODE;
  }
};

const convertRegisterTypeByRouterToNames = (value) => {
  switch (value) {
    case CUSTOMER:
      return CUSTOMER_CODE;
    case SUPERVISOR_TYPE_PATH:
      return "Supervisor";
    case OFFICIAL_TYPE_PATH:
      return "Oficial";
    case ASSISTANT_TYPE_PATH:
      return "Asistente";
    case CARPENTRY_COORDINATOR:
      return "Coordinador";
    case CARPENTRY_LEADER:
      return "Lider Carpinteria";
    case CARPENTRY_ASSISTANT:
      return "Auxiliar Carpinteria";
    case COO:
      return "COO";
    case OPERATION_COORDINATOR:
      return "Coordinador de Operaciones";
    case RESIDENT:
      return "Residente";
    case CFO:
      return "CFO";
    case ADMIN_COORDINATOR:
      return "Coordinador de Administración";
    case ADMIN_ASISTANT:
      return "Asistente de Administración";
    case HUMAN_RESOURCES:
      return "Recursos Humanos";
    case CSO:
      return "CSO";
    case PLANEATION_COORDINATOR:
      return "Coordinador de Planeación";
    case PLANEATION_ASISTANT:
      return "Asistente de Planeación";
    case DOCUMENTATION_ASISTANT:
      return "Asistente de Documentación";
    case PLANEATION_AUXILIARY:
      return "Auxiliar de Planeación";
    case POST_SOLD_AUXILIARY:
      return "Auxiliar de Postventa";
    case DESIGN_COORDINATOR:
      return "Coordinador de Diseño";
    case ARCHITECT_DESIGN:
      return "Arquitecto de Diseño";
    case DESIGN_AUXILIARY:
      return "Auxiliar de Diseño";
    case COMERCIAL_DIRECTOR:
      return "Director Comercial";
    case COMERCIAL_COORDINATOR:
      return "Coordinador Comercial";
    case COMERCIAL_ASISTANT:
      return "Asistente Comercial";
    case MARKETING_COORDINATOR:
      return "Coordinador de Marketing";

    default:
      return ERROR_CODE;
  }
};

const convertCodeToTitleByState = (key) => {
  switch (key) {
    case REQUESTED:
      return REQUESTED_NAME;
    case TO_BE_APPROVED:
      return TO_BE_APPROVED_NAME;
    case IN_DESING:
      return IN_DESING_NAME;
    case REJECTED:
      return REJECTED_NAME;
    case RECONFIG:
      return RESCHEDULE_NAME;
    case IN_PROGRESS:
      return IN_PROGRESS_NAME;
    case DONE:
      return DONE_NAME;
    case WARRANTY:
      return WARRANTY_NAME;
    case QUOTATION_IN_PROGRESS:
      return QUOTATION_IN_PROGRESS_NAME;
    case APPROVED:
      return APPROVED_NAME;
    case IN_PLANEATION:
      return READY_TO_EXECUTE_NAME;
    case PAUSED:
      return PAUSED_NAME;
    case WAITING_PAYMENT:
      return WAITING_PAYMENT_NAME;
    case CANCEL:
      return CANCEL_NAME;
    default:
      return "";
  }
};

const convertRegisterTypeByCodeToRouter = (value) => {
  switch (value) {
    case CUSTOMER_CODE:
      return CUSTOMER;
    case SERVICE_PROVIDER_SUPERVISOR_CODE:
      return SUPERVISOR_TYPE_PATH;
    case SERVICE_PROVIDER_OFFICIAL_CODE:
      return OFFICIAL_TYPE_PATH;
    case SERVICE_PROVIDER_ASSITANT_CODE:
      return ASSISTANT_TYPE_PATH;
    default:
      return ERROR_ROUTE;
  }
};

//
const convertTypePriority = (value) => {
  switch (value) {
    case 0:
      return PRIORITY_MEDIUM;
    case 1:
      return PRIORITY_HIGH;
    case 2:
      return PRIORITY_PRIORITY;
    default:
      return "";
  }
};

//
const convertTypePropertyByRouterToCode = (value) => {
  switch (value) {
    case HOUSE:
      return HOUSE_CODE;
    case APARTMENT:
      return APARTMENT_CODE;
    case LOCAL:
      return LOCAL_CODE;
    case WAREHOUSE:
      return WAREHOSE_CODE;
    case CONDOMINIUM:
      return HOUSE_CONDOMINIUM_CODE;
    case OFFICE:
      return OFFICE_CODE;
    case OTHER_PROPERTY:
      return OTHER_PROPERTY_CODE;
    default:
      return ERROR_CODE;
  }
};

const convertTypePropertyByCodeToRouter = (value) => {
  switch (value) {
    case HOUSE_CODE:
      return HOUSE;
    case APARTMENT_CODE:
      return APARTMENT;
    case LOCAL_CODE:
      return LOCAL;
    case WAREHOSE_CODE:
      return WAREHOUSE;
    case HOUSE_CONDOMINIUM_CODE:
      return CONDOMINIUM;
    case OFFICE_CODE:
      return OFFICE;
    case OTHER_PROPERTY_CODE:
      return OTHER_PROPERTY;
    default:
      return ERROR_ROUTE;
  }
};

//
const convertTypePropertyByCodeToName = (value) => {
  switch (value) {
    case HOUSE_CODE:
      return "Casa";
    case APARTMENT_CODE:
      return "Apartamento";
    case LOCAL_CODE:
      return "Local Comercial";
    case WAREHOSE_CODE:
      return "Bodega";
    case HOUSE_CONDOMINIUM_CODE:
      return "Propiedad horizontal";
    case OFFICE_CODE:
      return "Oficina";
    case OTHER_PROPERTY_CODE:
      return "Otro";
    default:
      return ERROR_ROUTE;
  }
};

const convertTypePropertyByRouteToName = (value) => {
  switch (value) {
    case HOUSE:
      return "Casa";
    case APARTMENT:
      return "Apartamento";
    case LOCAL:
      return "Local Comercial";
    case WAREHOUSE:
      return "Bodega";
    case CONDOMINIUM:
      return "Propiedad horizontal";
    case OFFICE_CODE:
      return "Oficina";
    case OTHER_PROPERTY:
      return "Otro";
    default:
      return ERROR_ROUTE;
  }
};

//
const convertUserTypeByRouterToCode = (value) => {
  switch (value) {
    case COMPANY:
      return COMPANY_CODE;
    case PARTICULAR:
      return PARTICULAR_CODE;
    case REAL_STATE:
      return REAL_STATE_CODE;
    case INSURANCE:
      return INSURANCE_CODE;
    case HORIZONTAL_HOUSE:
      return HORIZONTAL_HOUSE_CODE;
    case HORIZONTAL_PROPERTY:
      return HORIZONTAL_PROPERTY_CODE;
    case OTHER_USER:
      return OTHER_USER_CODE;
    default:
      return ERROR_CODE;
  }
};

const convertUserTypeByCodeToRouter = (value) => {
  switch (value) {
    case COMPANY_CODE:
      return COMPANY;
    case PARTICULAR_CODE:
      return PARTICULAR;
    case REAL_STATE_CODE:
      return REAL_STATE;
    case INSURANCE_CODE:
      return INSURANCE;
    case HORIZONTAL_HOUSE_CODE:
      return HORIZONTAL_HOUSE;
    case HORIZONTAL_PROPERTY_CODE:
      return HORIZONTAL_PROPERTY;
    case OTHER_USER_CODE:
      return OTHER_USER;
    default:
      return ERROR_ROUTE;
  }
};

const convertManagementTypeByRouterToCode = (value) => {
  switch (value) {
    case REAL_STATE_PERSONAL:
      return REAL_STATE_PERSONAL_CODE;
    case INSURANCE_PERSONAL:
      return INSURANCE_PERSONAL_CODE;
    case COMPANY_PERSONAL:
      return COMPANY_PERSONAL_CODE;
    case HOUSE_PERSONAL:
      return HOUSE_PERSONAL_CODE;
    case PROPERTY_PERSONAL:
      return PROPERTY_PERSONAL_CODE;
    case NEW_PERSONAL:
      return NEW_PERSONAL_PERSONAL_CODE;
    default:
      return ERROR_CODE;
  }
};

const convertManagementTypeByCodeToRouter = (value) => {
  switch (value) {
    case REAL_STATE_PERSONAL_CODE:
      return REAL_STATE_PERSONAL;
    case INSURANCE_PERSONAL_CODE:
      return INSURANCE_PERSONAL;
    case COMPANY_PERSONAL_CODE:
      return COMPANY_PERSONAL;
    case HOUSE_PERSONAL_CODE:
      return HOUSE_PERSONAL;
    case PROPERTY_PERSONAL_CODE:
      return PROPERTY_PERSONAL;
    case NEW_PERSONAL_PERSONAL_CODE:
      return NEW_PERSONAL;
    default:
      return ERROR_ROUTE;
  }
};

const convertTypePropertyByCodeToIcon = (value) => {
  switch (value) {
    case HOUSE_CODE:
      return CASA_ICON_SVG;
    case APARTMENT_CODE:
      return APTO_ICON_SVG;
    case LOCAL_CODE:
      return LOCAL_ICON_SVG;
    case WAREHOSE_CODE:
      return WAREHOUSE_ICON_SVG;
    case HOUSE_CONDOMINIUM_CODE:
      return CONDOMINIO_ICON_SVG;
    case OFFICE_CODE:
      return OFFICE_ICON_SVG;
    case OTHER_PROPERTY_CODE:
      return OTROS_ICON_SVG;
    default:
      return null;
  }
};

const convertPhaseNameToSpanishName = (value) => {
  switch (value) {
    case DESIGN_PHASE_NAME:
      return DESIGN_PHASE_NAME_SPANISH;
    case PLANEATION_PHASE_NAME:
      return PLANEATION_PHASE_NAME_SPANISH;
    case OPERATION_PHASE_NAME:
      return OPERATION_PHASE_NAME_SPANISH;
    default:
      return ERROR_NAME;
  }
};

const convertPhaseToColor = (key) => {
  switch (key) {
    case DESIGN_PHASE_NAME:
      return "#FF69B4";
    case PLANEATION_PHASE_NAME:
      return "#3A4D8F";
    case OPERATION_PHASE_NAME:
      return "#03C1E1";
    default:
      return "";
  }
};

const convertServiceProviderTypeByCodeToName = (value) => {
  switch (value) {
    case SERVICE_PROVIDER_SUPERVISOR_CODE:
      return SERVICE_PROVIDER_SUPERVISOR;
    case SERVICE_PROVIDER_OFFICIAL_CODE:
      return SERVICE_PROVIDER_OFFICIAL;
    case SERVICE_PROVIDER_ASSITANT_CODE:
      return SERVICE_PROVIDER_ASSITANT;
    default:
      return null;
  }
};

const convertPhaseKey = (key) => {
  switch (key) {
    case 0:
      return DESIGN_PHASE_NAME_SPANISH;
    case 1:
      return PLANEATION_PHASE_NAME_SPANISH;
    case 2:
      return OPERATION_PHASE_NAME_SPANISH;
    case 3:
      return "Gestión Comercial";
    default:
      return undefined;
  }
};

export {
  convertCodeToTitleByState,
  convertManagementTypeByCodeToRouter,
  convertManagementTypeByRouterToCode,
  convertPhaseKey,
  convertPhaseNameToSpanishName,
  convertPhaseToColor,
  convertRegisterTypeByCodeToRouter,
  convertRegisterTypeByRouterToCode,
  convertRegisterTypeByRouterToNames,
  convertServiceProviderTypeByCodeToName,
  convertTypePriority,
  convertTypePropertyByCodeToIcon,
  convertTypePropertyByCodeToName,
  convertTypePropertyByCodeToRouter,
  convertTypePropertyByRouteToName,
  convertTypePropertyByRouterToCode,
  convertTypeRegisterToManagementCode,
  convertTypeRegisterToNames,
  convertUserTypeByCodeToRouter,
  convertUserTypeByRouterToCode,
  convertUserTypeToRegisterTypeCode,
};
