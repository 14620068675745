import React, { useState } from "react";
import JUSTO_PAGO_LOGO_BLANCO from "../../../assets/icons/svg/justo_pago_logo_blanco.svg";
import NotificationsPcPanel from "./NotificationsPcPanel";
import "./menu.css";
import { tabs } from "./Tabs";
import { Button } from "antd";

const DesktopMenu = ({ userType, onNavigate }) => {
  const [showNotifications, setShowNotifications] = useState(false);

  const handleNotificationsToggle = () => {
    setShowNotifications((prev) => !prev);
  };

  return (
    <div className="desktop-menu">
      <div className="logo">
        <img src={JUSTO_PAGO_LOGO_BLANCO} alt="justo-pago-logo" />
      </div>
      <div className="menu-items">
        {tabs.map((item, index) => (
          <Button
            key={index}
            icon={<item.icon />}
            onClick={
              item.key === "notifications"
                ? handleNotificationsToggle
                : () => onNavigate(item.key)
            }
          >
            <p>{item.title}</p>
          </Button>
        ))}
      </div>
      {showNotifications && (
        <NotificationsPcPanel
          userType={userType}
          isInPcView={true}
          onClose={() => setShowNotifications(false)}
        />
      )}
    </div>
  );
};

export default DesktopMenu;
