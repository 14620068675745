import React from "react";
import "./home.css";
import { Avatar, Skeleton, Popover, Badge } from "antd-mobile";
import { UnorderedListOutline } from "antd-mobile-icons";
import { Dropdown, Tag, Progress, Card } from "antd";
import {
  configClass,
  configTitleIcon,
} from "components/molecules/container/Container";
import { getStateColor, moneyFormat } from "../services/services";
import { auxiliarPhasesArray, PHASE_ICONS } from "../components/Tabs";
import Navigation from "../components/Navigation";
import { getPercentPhases } from "views/services-provider/ps-new-entry/phases";
import ProgressBar from "components/atoms/progess-bar/ProgressBar";
import WALLET_ICON from "assets/icons/svg/icons8-billetera.svg";
import PAY_ICON from "assets/icons/svg/pay.svg";
import PROGRESSION_ICON from "assets/icons/svg/progression.svg";
import Services from "../services/Services.jsx";

const NewHome = ({
  servicesInCourse,
  service,
  form_user,
  history,
  handleChangeService,
  phasesOptions,
  setChat,
  loading,
  actions,
  states,
  data,
  servicesList,
  navigatorService,
}) => {
  const [totalValue, setTotalValue] = React.useState(null);
  const [valueToPay, setValueToPay] = React.useState(null);

  let [phases, totalPercent] = getPercentPhases(service?.state.globalState);

  React.useEffect(() => {
    if (data) {
      setTotalValue(data[0]?.payment.paymentInfo.to_pay);
      setValueToPay(
        data[0]?.payment.paymentInfo.to_pay - data[0]?.payment.paymentInfo.payed
      );
    }
  }, [data]);

  const items = servicesInCourse?.map((service, index) => {
    if (!service) return null;

    return {
      key: index.toString(),
      label: (
        <div
          className="inside-menu"
          onClick={() => handleChangeService(service)}
        >
          <h3>{service.metadata.property.nickname.split("-")[1]}</h3>
          <p>{service.unique_key}</p>
          <Tag color={getStateColor(configClass(service.state.stage))}>
            {configTitleIcon(service.state.stage)}
          </Tag>
        </div>
      ),
    };
  });

  return service && service.bitacle_v === 2 ? (
    <main className="main-home">
      <section id="section-home-id" className="section-home">
        {loading ? (
          <article id="user-info">
            <Skeleton
              className="skeleton-avatar"
              animated
              style={{ "--size": "80px" }}
            />
            <Skeleton.Paragraph lineCount={2} animated />
          </article>
        ) : (
          <article id="user-info">
            <div>
              <h2>{service?.metadata.property.nickname}</h2>
              <p>{service?.unique_key}</p>
            </div>
            <Dropdown
              menu={{ items }}
              placement="bottomLeft"
              trigger={["click"]}
            >
              <UnorderedListOutline
                className="icon-menu"
                onClick={(e) => e.preventDefault()}
                type="button"
              />
            </Dropdown>
          </article>
        )}

        {loading ? (
          <article id="progress">
            <Skeleton animated style={{ height: "40px", width: "60%" }} />
            <Skeleton animated style={{ height: "20px", width: "40%" }} />
            <Skeleton animated style={{ height: "10px", width: "80%" }} />
          </article>
        ) : (
          <article id="progress">
            {states.walletView && (
              <Card
                title="Detalle del Servicio"
                type="inner"
                bordered
                className="ant-card-wallet"
              >
                <div className="pay-card-container">
                  <div className="pay-card-inside">
                    <span>
                      <label>Valor del servicio: </label>
                      <p>
                        {moneyFormat({
                          value: totalValue,
                        })}
                      </p>
                    </span>
                    <Popover
                      content="Abonar al pago"
                      placement="top-end"
                      mode="dark"
                      trigger="click"
                      visible={states.popupVisibility.pay}
                    >
                      <Avatar
                        onClick={() => {
                          actions.setOpenPayModal(true);
                          actions.setPopupVisibility({
                            pay: false,
                            wallet: false,
                          });
                        }}
                        style={{
                          "--size": "30px",
                          cursor: " pointer",
                          alignSelf: "flex-start",
                        }}
                        src={PAY_ICON}
                        alt="Icono de pago"
                      />
                    </Popover>
                  </div>
                  <div className="pay-card-inside-2">
                    <aside>
                      <label>Pagado: </label>
                      <p>
                        {moneyFormat({
                          value: data[0].payment.paymentInfo.payed,
                        })}
                      </p>
                      <Progress
                        percent={(
                          data[0].payment.paymentInfo.percent_payed * 100
                        ).toFixed(0)}
                        size="small"
                        strokeColor="#52c41a"
                        style={{ fontSize: "10px" }}
                      />
                    </aside>
                    <aside>
                      <label>Restante: </label>
                      <p>
                        {moneyFormat({
                          value: valueToPay,
                        })}
                      </p>
                      <Progress
                        percent={((valueToPay * 100) / totalValue).toFixed(0)}
                        size="small"
                        strokeColor="#f5222d"
                        style={{ fontSize: "10px" }}
                        status="exception"
                      />
                    </aside>
                  </div>
                </div>
              </Card>
            )}
            <div>
              <div className="progress-icons-header">
                <Avatar
                  style={{
                    "--size": "30px",
                  }}
                  src={PROGRESSION_ICON}
                />
                <Popover
                  content="Ver detalle de pago"
                  placement="top-end"
                  mode="ligth"
                  trigger="click"
                  visible={states.popupVisibility.wallet && data}
                >
                  <Badge
                    content={
                      data && data[0].wallet.is_to_paid ? Badge.dot : null
                    }
                  >
                    {data && (
                      <Avatar
                        onClick={() => {
                          actions.setWalletView(!states.walletView);
                          actions.setPopupVisibility({
                            pay: !states.popupVisibility.pay ? true : false,
                            wallet: false,
                          });
                        }}
                        style={{ "--size": "30px", cursor: "pointer" }}
                        src={WALLET_ICON}
                      />
                    )}
                  </Badge>
                </Popover>
              </div>
              <span className="progress-title-span">
                <h2>Mi progreso</h2>
                <p>{totalPercent || 0}%</p>
              </span>
            </div>
            <div className="progress-container">
              <div className="progress-bar-container">
                {service?.state?.globalState && (
                  <ProgressBar phases={phases} total={totalPercent} />
                )}
              </div>
              <p>{totalPercent || 0}%</p>
            </div>
          </article>
        )}

        {auxiliarPhasesArray.map((phase, index) => {
          const matchedPhase = phasesOptions?.find(
            (option) => Number(option?.phase?.code) === phase.id
          );

          if (loading) {
            return (
              <article key={index}>
                <Skeleton
                  className="skeleton-avatar"
                  animated
                  style={{ "--size": "110px", alignSelf: "flex-end" }}
                />
                <Skeleton.Paragraph lineCount={1} animated />
              </article>
            );
          }

          if (!matchedPhase) return null;

          const { style_id, name, id } = phase;
          const icon = PHASE_ICONS[style_id];

          return (
            <article
              onClick={() => setChat(phase, id)}
              id={style_id}
              key={index}
            >
              <Avatar className="avatar-phase" src={icon} />
              <h2>{name}</h2>
            </article>
          );
        })}
      </section>
      <Navigation userType={form_user.register_type} history={history} />
    </main>
  ) : (
    <Services
      navigatorService={navigatorService}
      servicesList={servicesList}
      userType={form_user.register_type}
      history={history}
    />
  );
};

export default NewHome;
