import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import { useSelector, shallowEqual } from 'react-redux';
import Search from 'antd/es/transfer/search';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { Space } from 'antd-mobile';
import { SearchOutlined } from '@ant-design/icons';
const BitacleSearchBar = () => {
  const chat = useSelector((state) => state.bitacle.chat, shallowEqual)
  const [searchMessage, setSearchMessage] = useState("");
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onSearch = useCallback((e) => {


    setSearchMessage(e.target.value);
  }, []);

  const filteredMessages = useMemo(() => {
    if (!searchMessage) return [];
    const messages = chat.map((msg) => {
      if (msg.type == "activity") {
        return {
          ...msg,
          message: msg.detail,
        }

      }
      return msg
    }
    );
    return messages.filter((msg) => {
      const normalizedMessage = msg.message?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      const normalizedInput = searchMessage.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      return normalizedMessage?.includes(normalizedInput)
    })

  }, [searchMessage])

  function truncateMessage(message, maxLength) {
    if (message.length > maxLength) {
      return message.slice(0, maxLength) + '...';
    }
    return message;
  }


  return (
    <>

      <Button onClick={showDrawer} type="dashed" shape="circle" icon={<SearchOutlined />} />

      <Drawer title="Buscar mensajes" onClose={onClose} open={open}>
        <Search size="large" placeholder="Mensaje" loading enterButton onChange={onSearch} />
        <a href='#message-1' onClick={onClose}>...</a>
        <Space direction="vertical" style={{ width: '100%' }}>
          {filteredMessages.map((msg, index) => (
            <a
              key={index}
              href={`#message-${msg.position}`}
              onClick={onClose}
              style={{
                display: 'block',
                padding: '10px 15px',
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                borderRadius: '8px',
                textDecoration: 'none',
                color: '#000',
                transition: 'background-color 0.3s',
                marginBottom: '8px',
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.1)')}
              onMouseLeave={(e) => (e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.05)')}
            >
              <span style={{ fontWeight: 'bold', display: 'block' }}>{msg.emisor.name ?? msg.user}</span>
              <span style={{ opacity: 0.8 }}>{truncateMessage(msg.message, 50)}</span>
            </a>
          ))}
        </Space>

      </Drawer>
    </>
  );
};
export default BitacleSearchBar;