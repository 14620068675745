import { AlertOutlined, ClearOutlined, ProductOutlined, TruckOutlined } from "@ant-design/icons";
import { Button } from "antd";

const CLEAN = 'clean'
const ORDER = 'order'
const DEBRIS = 'debris'
const UNEXPECTED = 'unexpected'

export default function AddNewOptions({ onHandleNewRequest }) {
  function onClick(type) {
    switch (type) {
      case CLEAN:
        onHandleNewRequest(CLEAN);
        break;
      case ORDER:
        onHandleNewRequest(ORDER);
        break;
      case DEBRIS:
        onHandleNewRequest(DEBRIS);
        break;
      case UNEXPECTED:
        onHandleNewRequest(UNEXPECTED);
        break;
      default:

    }
  }

  return (
    <>
      <section className="add-new-option">
        <Button
          size="large"
          icon={<AlertOutlined />}
          onClick={() => onClick(UNEXPECTED)}
        >
          Nuevo imprevisto
        </Button>

        <Button
          size="large"
          onClick={() => onClick(ORDER)}
          icon={<ProductOutlined />}
        >
          Solicitar pedido
        </Button>

        <Button
          size="large"
          onClick={() => { onClick(CLEAN) }}
          icon={<ClearOutlined />}
        >
          Solicitar servicio de aseo
        </Button>

        <Button
          size="large"
          onClick={() => { onClick(DEBRIS) }}
          icon={<TruckOutlined />}
        >
          Solicitar retiro de escombros
        </Button>
      </section>
    </>
  )
}
