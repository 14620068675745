import React from "react";
import { Button, Space, FloatingPanel, Image, ImageViewer } from "antd-mobile";
import { FolderOutline, TextOutline } from "antd-mobile-icons";
import "./components.css";

const initialAnchors = [
  600,
  window.innerHeight * 0.4,
  window.innerHeight * 0.8,
];

const mapService = (data) => {
  const { obj } = data;

  const documents = [
    {
      title: "Acta de diseño y carpinteria",
      url: obj?.service?.metadata?.design_brief?.signed_file,
      type: "pdf",
    },
    {
      title: "Contrato otro si",
      url: obj?.service?.metadata?.reinforcement?.signed_file,
      type: "pdf",
    },
    {
      title: "Plano",
      url: obj?.quotation?.survey_quotation?.property_type?.images[0]?.url,
      type: "image",
    },
  ];

  return documents;
};

const DocsPanel = (obj) => {

  const target = React.useRef(null);
  const [showDocs, setShowDocs] = React.useState(false);
  const [anchors, setAnchors] = React.useState(initialAnchors);
  const [data, setData] = React.useState([]);
  const [imageVisible, setImageVisible] = React.useState(false);
  const [doc, setDoc] = React.useState(null);

  React.useEffect(() => {
    const data = mapService(obj);
    setData(data);
  }, [obj]);

  const onHeightChange = (height) => {
    setAnchors([100, 100, 600]);
    if (height <= 100) {
      setShowDocs(false);
    }
  };

  const onButtonClick = () => {
    setAnchors(initialAnchors);
    setShowDocs(true);
  };

  return (
    <>
      <Button className="float-button" onClick={() => onButtonClick()}>
        <Space>
          <FolderOutline />
        </Space>
      </Button>
      {showDocs && (
        <FloatingPanel onHeightChange={onHeightChange} anchors={anchors}>
          <div ref={target} className="grid-docs">
            {data.map((doc, index) => {
              return (
                <div className="doc" key={index}>
                  <Space
                    onClick={() => {
                      setImageVisible(true);
                      setDoc(doc);
                    }}
                    wrap
                  >
                    <h4>{doc.title}</h4>
                    {doc.type === "image" ? (
                      <Image
                        src={doc.url}
                        width={150}
                        height={150}
                        fit="fill"
                      />
                    ) : (
                      <Button
                        onClick={() => window.open(doc.url)}
                        type="primary"
                      >
                        <Space>
                          <TextOutline />
                        </Space>
                        <Space>
                          <p style={{ paddingLeft: "10px" }}>Ver documento</p>
                        </Space>
                      </Button>
                    )}
                  </Space>
                </div>
              );
            })}
          </div>
        </FloatingPanel>
      )}
      {doc?.type === "image" && (
        <ImageViewer
          visible={imageVisible}
          image={doc.url}
          onClose={() => setImageVisible(false)}
        />
      )}
    </>
  );
};

export default DocsPanel;
