import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { convertSnapshotToList } from "../../../callbacks/list";
import { updateServicesList } from "../../../reducers/servicesList.reducer.js";
import { DETAILS, PHASES } from "../../../router/routes";
import {
  getAllServicesInProgress,
  getAllServicesInit,
} from "../../../services-controller/database";
import Services from "./Services.jsx";
import PayModalController from "views/wallet/modal/PayModalController";
import {
  getAllEntriesByService,
  getPendingPaymentAmount,
  getServiceWalletById,
} from "services-controller/http.cleinte.controller";

const LABEL_IN_PROGRESS = "En curso";
const LABEL_DONE = "Pasados";

const ServicesController = ({ history }) => {
  const [servicesListInit, setServicesListInit] = useState([]);
  const [servicesListInProgress, setServicesListInProgress] = useState([]);
  const [data, setData] = useState(null);
  const [forAprove, setForAprove] = useState(false);
  const { servicesList } = useSelector((state) => state.services_list);
  const dispatch = useDispatch();
  // state

  useEffect(() => {
    const listenerServicesInit = getAllServicesInit((snapshot) =>
      updateList(snapshot, setServicesListInit)
    );

    const listenerServicesProgress = getAllServicesInProgress((snapshot) =>
      updateList(snapshot, setServicesListInProgress)
    );

    return () => {
      listenerServicesInit();

      listenerServicesProgress();
    };
  }, []);

  const constructorList = (servicesListInit, servicesListInProgress) => {
    const servicesListUpdated = [
      [
        LABEL_IN_PROGRESS,
        [
          ...servicesListInit,
          ...servicesListInProgress.filter(
            (service) =>
              service.state.stage === 0 ||
              service.state.stage === 1 ||
              service.state.stage === 2 ||
              service.state.stage === 4 ||
              service.state.stage === 5 ||
              service.state.stage === 7 ||
              service.state.stage === 8 ||
              service.state.stage === 9 ||
              service.state.stage === 10 ||
              service.state.stage === 11 ||
              service.state.stage === 12
          ),
        ],
      ],
      [
        LABEL_DONE,
        [
          ...servicesListInProgress.filter(
            (service) =>
              service.state.stage === 3 ||
              service.state.stage === 6 ||
              service.state.stage === 13
          ),
        ],
      ],
    ];
    return servicesListUpdated;
  };

  // general
  useEffect(() => {
    const servicesListUpdated = constructorList(
      servicesListInit,
      servicesListInProgress
    );
    dispatch(updateServicesList(servicesListUpdated));
  }, [servicesListInit, servicesListInProgress]);

  useEffect(() => {
    if (servicesList[0][1].length > 0) {
      setForAprove(
        servicesList[0][1].find((service) => service.state.stage === 0)
      );
    } else if (servicesList[0][1].length === 0) {
      setForAprove(true);
    }
  }, [servicesList]);
  const updateList = (snapshot, dispatch) => {
    if (typeof snapshot === "object") {
      const auxList = convertSnapshotToList(snapshot.val());
      localStorage.setItem("servicesList", JSON.stringify(auxList));

      dispatch(auxList);
    }
  };

  const navigatorService = async (id, stage, version) => {
    const { bitacle } = await getAllEntriesByService(id);

    //legacy entry unique_key is unique string and new entry unique_key is an array index
    const someLegacy = bitacle[0].entrys?.some(
      (entry) => entry.unique_key?.length > 1
    );
    if (bitacle[0].entrys.length > 1 && !someLegacy) {
      version = 2;
    } else {
      version = 1;
    }
    if (version === 2) {
      history.push(`${PHASES}/${id}`);
    } else {
      history.push(`${DETAILS}/${id}/${stage}`);
    }
  };

  const hasServiceDoubts = async (service) => {
    const wallet = await getServiceWalletById(service);
    const payment = await getPendingPaymentAmount(service);
    return {
      wallet,
      payment,
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      const results = await Promise.all(
        servicesListInProgress.flatMap(async (service) => {
          const res = await hasServiceDoubts(service.unique_key);
          return res; // Retorna el resultado de cada servicio
        })
      );

      setData(results);
    };

    if (servicesListInProgress.length > 0) {
      fetchData();
    }
  }, [servicesListInProgress]);

  return (
    <>
      <Services
        navigatorService={navigatorService}
        servicesList={servicesList}
        forAprove={forAprove}
      />
      <PayModalController data={data} />
    </>
  );
};

export default withRouter(ServicesController);
