import { DeleteOutlined, PlusOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Empty, Form, Image, InputNumber, message, Modal, Result, Select, Space } from 'antd';
import { FloatingPanel } from 'antd-mobile';
import { ReactComponent as XIcon } from "assets/icons/svg/x.svg";
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { camelCase, getEmisorFromUser } from 'res/utils';
import { createNewServiceRequest, getAllMaterials } from 'services-controller/http.cleinte.controller';

export default function OrderAdditionalService(
  { requestType,
    open,
    closeConfirm,
    isDialogToConfirm,
    openDialogToConfirm,
    setCloseModal,
    setOpenForm,
    setCloseForm,
  }) {


  const anchors = [106, window.innerHeight * 0.9];
  const anchorRef = React.useRef(null)
  const [form] = Form.useForm();
  const [materials, setMaterials] = useState({ isLoading: false, isSuccess: false, isError: false, data: [], parsedData: [], subParsedData: [] });
  const [dataToSend, setDataToSend] = useState([]);
  const [result, setResult] = useState({ status: "success", description: "Servicio solicitado con éxito", title: "Solicitud exitosa" })
  const [isShowResult, setIsShowResult] = useState(false)
  const [loading, setLoading] = useState(false)

  const user = useSelector(state => state.auth.user)
  const emisor = useMemo(() => getEmisorFromUser(user), [user])
  const id = useParams().id

  const sendRequest = async () => {
    try {
      setLoading(true)
      closeConfirm()

      const requestObj = { type: requestType, reason: dataToSend.reason, materials: dataToSend.materialList }

      const response = await createNewServiceRequest(id, requestObj, emisor);
      console.log(response)

      if (!response.error) {
        setResult({ status: "success", description: "Materiales solicitados con éxito", title: "Solicitud exitosa" })
      }

    } catch (error) {

      if (error.response.data.message) {
        setResult({ status: "error", description: error.response?.data?.message, title: "Error al solicitar" })
      } else {
        setResult({ status: "error", description: "Error al solicitar el servicio", title: "Error al solicitar" })
      }

    } finally {

      setLoading(false)
      setIsShowResult(true)
      setTimeout(() => {
        setIsShowResult(false)
        // setCloseForm()
      }, 3000)



    }

  }


  const onCancel = () => {
    setCloseForm();
  }

  const onSubmit = (values) => {
    console.log(values, "va")
    setDataToSend(values)
    openDialogToConfirm()
  }

  const optionsWhy = [
    { label: "Robo", value: "robo" },
    { label: "Perdida", value: "perdida" },
  ]


  const getMaterials = () => {
    (async () => {
      setMaterials(prev => ({ ...prev, isLoading: true }));

      try {
        const res = await getAllMaterials()
        setMaterials(prev => ({ ...prev, isSuccess: true, data: res }))
      } catch {
        setMaterials(prev => ({ ...prev, isError: true }))
        message.error("Hubo un error al recuperar los materiales")
      } finally {
        setMaterials(prev => ({ ...prev, isLoading: false }))
      }
    })()
  }

  useEffect(() => {
    if (materials.data.length) {
      const selectOptions = materials.data.map(material => {
        const materialImage = material.images?.at(0)?.url ?? material.options?.at(0).images?.at(0)?.url;

        return {
          label: (
            <div className="select-option-wrapper">
              {materialImage && (
                <Image
                  src={materialImage}
                  alt={material.name}
                  className="material-image"
                  width={52}
                  height={52}
                  preview={false}
                />
              )}

              <span className="select-option-name">
                {material.name}
              </span>
            </div>
          ),
          value: material.id,
          text: material.name,
        }
      })

      setMaterials(prev => ({ ...prev, parsedData: selectOptions }))
    }
  }, [materials.data])



  const onAdd = () => {
    const materialList = form.getFieldValue("materialList");
    materialList.push({ material: null, quantity: null });
    form.setFieldsValue({ materialList });
  }

  useEffect(() => {
    if (open && !loading) {
      anchorRef?.current?.setHeight(window.innerHeight * 0.9)
    }
    if (isDialogToConfirm || isShowResult) {
      anchorRef?.current?.setHeight(120)
    }
  }, [open, isDialogToConfirm, isShowResult])

  return <>
    <FloatingPanel className='floating-material ' anchors={anchors} ref={anchorRef}>
      <main className="order-additional-material">
        <header className='header-container'>
          <h1>Material(es) adicional(es)</h1>
          <Button
            className="close-btn-floating-panel"
            onClick={onCancel}
            icon={<XIcon />}
            size="large"
            type="primary"
          >
          </Button>
        </header>

        <Form
          form={form}
          onFinish={onSubmit}
          initialValues={{ materialList: [{}] }}
          layout="vertical"
          rootClassName="material-form"
        >
          <Form.List name="materialList">
            {(fields, { remove }) => (
              <section className="material-list" onPointerEnter={(event) => { event.stopPropagation() }}>
                {!fields.length && (<Empty description="No hay materiales adicionales" />)}

                {fields.map(({ key, name, ...restField }) => {

                  return (
                    <article className="material-list-item" key={key}>
                      <Button
                        onClick={() => remove(name)}
                        danger
                        size="small"
                        type="primary"
                        className="remove-item-btn"
                        icon={<DeleteOutlined />}
                      />

                      <div className="material-item-wrapper">
                        <Form.Item
                          {...restField}
                          rules={[
                            { required: true, message: "Debes seleccionar el material" },
                          ]}
                          name={[name, "material_id"]}
                          label="Material"
                        >
                          <Select
                            onDropdownVisibleChange={(value) => {
                              if (value && !materials.data.length) {
                                getMaterials()
                              }
                            }}
                            options={materials.parsedData}
                            loading={materials.isLoading}
                            showSearch
                            optionFilterProp="text"
                            filterSort={(optionA, optionB) => {
                              return (optionA?.text ?? '').toLowerCase().localeCompare((optionB?.text ?? '').toLowerCase())
                            }}
                            allowClear
                            className="material-select"
                          />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          rules={[
                            { required: true, message: "La cantidad del material es necesaria" },
                          ]}
                          name={[name, "quantity"]}
                          label="Cantidad"
                        >
                          <InputNumber size='large' className="quantity-input" />
                        </Form.Item>
                      </div>
                    </article>
                  )
                })}
                <Form.Item
                  rules={[
                    { required: true, message: "Debe escribir la razon por la cual necesitas este material" },
                  ]}
                  name={"reason"}
                  label="¿Porqué necesitas estos materiales?"
                >
                  <Select size='large' options={optionsWhy} className="why-select" />

                </Form.Item>

              </section>
            )}
          </Form.List>

          <footer className="form-actions-section">
            <Button icon={<PlusOutlined />} className='to-add-button' type='dashed' onClick={onAdd} block>
              Agregar Material
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              icon={<SendOutlined />}
              iconPosition='end'
              size="large"
              className="order-submit-btn"
              onClick={() => {
                console.log("hola")
              }}
              block
            >
              Realizar pedido
            </Button>
          </footer>
        </Form>
      </main>

    </FloatingPanel>

    <Modal
      centered
      open={isDialogToConfirm}
      title="¿Estás seguro de realizar el pedido?"
      onCancel={closeConfirm}
      onOk={sendRequest}
    >
      <section className='add-confirm-modal'>
        {
          dataToSend.materialList?.map((material, index) => {

            const materialFind = materials.data.find(mat => mat.id === material.material_id)
            return (
              <article className="material-list-item" key={index}>
                <div className="material-item-wrapper">
                  <div className="material-item">

                    <Image
                      src={materialFind.images?.at(0)?.url ?? materialFind.options?.at(0).images?.at(0)?.url}
                      alt={materialFind.name}
                      className="material-image"
                      width={52}
                      height={52}
                      preview={false}
                    />
                    <Space className='material-detail'>
                      <Space direction='vertical'>
                        {/* bold */}
                        <span>Material</span>
                        <span className="material-name">{camelCase(materialFind.name)}</span>
                      </Space>

                      <Space direction='vertical'>
                        <span>Cantidad</span>
                        <span className="material-quantity">{material.quantity}</span>
                      </Space>
                    </Space>

                  </div>
                </div>
              </article>
            )
          })
        }
      </section>
    </Modal >

    <Modal centered open={isShowResult} footer={false}>
      <Result status={result.status} title={result.description} description={result.description} />
    </Modal>
  </>


}
