import { Modal, Space } from "antd-mobile";
import InputFile from "components/InputFile/InputFile";
import { Spin } from "antd";
import "../wallet.css";

const PayModal = ({ open, state, functions, style, data, loading, user }) => {
  return (
    <>
      {data?.map((service) => {
        return (
          <Modal
            visible={open}
            key={service.wallet.service_id}
            title="Pago requerido"
            onClose={() => state.setOpenModal(false)}
            className="pay-modal-required"
            actions={[
              {
                key: "pay_ready",
                text: "Subir pago",
                onClick: () =>
                  functions.onConfirm("pay_ready", service.wallet.service_id),
                style: style,
              },
            ]}
            content={
              <Spin spinning={loading}>
                <Space className="pay-modal-space" direction="vertical">
                  <div>
                    {`${
                      user.register_data.name
                    }, para comenzar la siguiente fase
                en tu proyecto debes pagar $${service?.payment.pendingAmount.toLocaleString()}, equivalente al ${
                      service?.payment.percentContinue * 100
                    }% del total del proyecto.`}
                  </div>
                </Space>
              </Spin>
            }
          />
        );
      })}

      <Modal
        visible={state.uploadInvoiceModal}
        onClose={() => state.setUploadInvoiceModal(false)}
        title="Cargar comprobante"
        closeOnMaskClick
        actions={[
          {
            key: "upload_invoice",
            text: "Subir comprobante",
            onClick: () => functions.onUploadInvoice(),
            style: style,
          },
        ]}
        content={
          <div className="file-upload-container">
            <label>Debes cargar solo un archivo</label>
            <InputFile
              images={state.files}
              deleteMediaSource={functions.onDeleteFile}
              onAddFiles={state.setFiles}
              limit={1}
              filesAccept={[".png", ".jpg", ".heic", ".jpeg", ".pdf"]}
            />
          </div>
        }
      />
    </>
  );
};

export default PayModal;
