
const ACTIONS = {
  SET_CHAT_PHASE_SELECTED: "SET_CHAT_PHASE_SELECTED",
  SET_MEDIA_SECTION_MINIMIZED: "SET_MEDIA_SECTION_MINIMIZED",
  SET_ACTIVITIES_SECTION_MINIMIZED: "SET_ACTIVITIES_SECTION_MINIMIZED",
  SET_PHASES: "SET_PHASES",
  SET_INITIAL_CHAT: "SET_INITIAL_CHAT",
  UPDATE_MESSAGE: "UPDATE_MESSAGE",
  ADD_MESSAGE: "ADD_MESSAGE",
  ADD_MORE_MESSAGES: "ADD_MORE_MESSAGES",
}

const initialState = {
  chatPhaseSelected: {
    name: "",
    key: "",
  },
  chat: [
  ],
  isMediaSectionActive: false,
  isActivitiesSectionActive: false,
  phases: [],
}

export const setPhases = (phases) => {
  return { type: ACTIONS.SET_PHASES, payload: phases }
}

export const setChatPhaseSelected = ({ key, name }) => {
  return { type: ACTIONS.SET_CHAT_PHASE_SELECTED, payload: { key, name }, }
}

export const setInitialChat = (chat) => {
  return { type: ACTIONS.SET_INITIAL_CHAT, payload: chat, }
}

export const updateMessage = (message) => {
  return { type: ACTIONS.UPDATE_MESSAGE, payload: message, }
}

export const addMessage = (message) => {
  return { type: ACTIONS.ADD_MESSAGE, payload: message, }
}

export const setMediaSectionActive = (isMinimized) => {
  return { type: ACTIONS.SET_MEDIA_SECTION_MINIMIZED, payload: isMinimized, }
}

export const setActivitiesSectionActive = (isMinimized) => {
  return { type: ACTIONS.SET_ACTIVITIES_SECTION_MINIMIZED, payload: isMinimized, }
}

export const addMoreMessages = (messages) => {
  return { type: ACTIONS.ADD_MORE_MESSAGES, payload: messages, }
}


export default function bitacleReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.SET_CHAT_PHASE_SELECTED:
      return { ...state, chatPhaseSelected: { ...state.chatPhaseSelected, ...payload } }

    case ACTIONS.SET_MESSAGE_CHAT:
      return {
        ...state,
        chat: [
        ]
      }

    case ACTIONS.SET_MEDIA_SECTION_MINIMIZED:
      return { ...state, isMediaSectionActive: payload }

    case ACTIONS.SET_ACTIVITIES_SECTION_MINIMIZED:
      return { ...state, isActivitiesSectionActive: payload }

    case ACTIONS.SET_PHASES:
      return { ...state, phases: payload }

    case ACTIONS.UPDATE_MESSAGE:
      return {
        ...state,
        chat: state.chat.map((item) => {
          if (item.position === payload.position) {
            return { ...item, ...payload }
          }
          return item
        })
      }

    case ACTIONS.ADD_MESSAGE:
      return {
        ...state,
        //first find for position
        chat:
          state.chat.find(item => item.position === payload.position)
            ? state.chat.map(item =>
              item.position === payload.position ? { ...item, ...payload } : item
            )
            : [...state.chat, payload]
      }

    case ACTIONS.SET_INITIAL_CHAT:
      return {
        ...state,
        chat: payload,
      }

    case ACTIONS.ADD_MORE_MESSAGES:
      return {
        ...state,
        chat: [...payload, ...state.chat].sort((a, b) => a.position - b.position)
      }

    default:
      return state;
  }
}
