import { Modal, Result } from "antd-mobile";
import { useEffect, useState } from "react";
import { uploadPossiblePayment } from "services-controller/http.cleinte.controller";
import { uploadMultipleEvidenceResources } from "services-controller/storage";
import { useSelector } from "react-redux";
import PayModal from "./PayModal";
import { message } from "antd";

const PayModalController = ({ data, actions, states }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const [files, setFiles] = useState([]);
  const [isSendedToPayRevision, setIsSendedToPayRevision] = useState(false);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(null);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    setTimeout(() => {
      setIsSendedToPayRevision(false);
    }, 1000);
  }, [isSendedToPayRevision]);

  useEffect(() => {
    if (data) {
      setLoading(false);
      const open = data?.some((service) => service.wallet.is_to_paid);
      console.log(open);

      actions?.setOpenPayRequiredModal(open);
    }
  }, [data]); //eslint-disable-line

  const onUploadInvoice = async () => {
    if (!files.length) {
      messageApi.error("Debes cargar un archivo");
      return;
    }
    const uploadedFiles = await uploadMultipleEvidenceResources(files);

    //solo se le permite enviar un archivo
    const invoice = {
      ...uploadedFiles.at(0),
      uploaded_date: new Date(),
    };

    await uploadPossiblePayment(id, invoice);

    setIsSendedToPayRevision(true);
    actions?.setOpenPayModal(false);
    actions?.setOpenPayRequiredModal(false);
    setFiles([]);
  };

  const onDeleteFile = (index) => {
    setFiles(files.filter((file, i) => i !== index));
  };

  const onConfirm = (flag, id) => {
    actions?.setOpenPayModal(flag === "pay_ready" && true);
    setId(id);
    actions?.setOpenPayRequiredModal(false);
  };

  let state = {
    files,
    setFiles,
    uploadInvoiceModal: states?.openPayModal,
    setUploadInvoiceModal: actions?.setOpenPayModal,
  };

  let functions = {
    onUploadInvoice,
    onDeleteFile,
    onConfirm,
  };

  const style = {
    backgroundColor: "#652d52",
    color: "white",
    padding: "10px 20px",
  };

  return (
    <>
      {isSendedToPayRevision ? (
        <Modal
          visible={isSendedToPayRevision}
          content={
            <Result
              status="success"
              description="El pago se envio a revisión"
            />
          }
        />
      ) : (
        <PayModal
          open={states?.openPayRequiredModal}
          data={data}
          loading={loading}
          functions={functions}
          style={style}
          state={state}
          user={auth.user}
        />
      )}
      {contextHolder}
    </>
  );
};

export default PayModalController;
