import React, { useState } from 'react';
import { Form, Button } from 'antd-mobile';
import { ReactComponent as XIcon } from "assets/icons/svg/x.svg";
import { Steps } from 'antd';
import "./newUnexpectedForm.css"

const NewUnexpectedForm = ({ onClose }) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});

  const handleFinish = (values) => {
    setFormData(values);
    console.log('Form Data:', values);
  };
  const description = 'This is a description.';
  return (
    <div className="steps-container-new-unexpected" style={{ padding: "8px" }}>
      <header className='header-container'>
        <h1>Material(es) adicional(es)</h1>
        <Button
          className="close-btn-floating-panel"
          onClick={onClose}
          icon={<XIcon />}
          size="large"
          type="primary"
        >
        </Button>
      </header>
      <Steps
        direction='horizontal'
        current={1} items={[
          { title: 'Selecciona el servicio' },
          { title: 'En qué espacios se aplica' },
          { title: 'Confirmar' },
        ]} />
    </div>
  );
};

export default NewUnexpectedForm;