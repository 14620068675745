const SERVICES_LIST_ACTION = 'SERVICES_LIST_ACTION'
const SERVICE_LIST_IN_PROGRESS_ACTION = 'SERVICE_LIST_IN_PROGRESS_ACTION'


const initialStateServicesList = {
	servicesListInProgress: [],
	servicesList: [],
	servicesListInit: []
}

const reducerServicesList = (state = initialStateServicesList, action) => {
	const { type, payload } = action

	switch (type) {
		case SERVICES_LIST_ACTION:
			return {
				...state,
				servicesList: payload
			}
		case SERVICE_LIST_IN_PROGRESS_ACTION:

			return {
				...state,
				servicesListInProgress: payload
			}
		case 'SERVICES_LIST_INIT_ACTION':
			return {
				...state,
				servicesListInit: payload
			}

		default:
			return state
	}
}

const updateServicesList = (payload) => {
	return {
		type: SERVICES_LIST_ACTION,
		payload
	}
}

const updateServiceListInProgress = (payload) => {
	return {
		type: SERVICE_LIST_IN_PROGRESS_ACTION,
		payload
	}
}

const updateServicesListInit = (payload) => {
	return {
		type: 'SERVICES_LIST_INIT_ACTION',
		payload
	}
}




export { reducerServicesList , updateServicesList, updateServiceListInProgress, updateServicesListInit}

