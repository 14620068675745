import React, { useRef, useEffect } from "react";
import BELL_ICON_SVG from "../../../assets/icons/svg/bell.svg";
import Notifications from "../notifications/Notifications";
import PsNotifications from "views/services-provider/ps-notifications/PsNotifications";
import "./menu.css";
import { Divider } from "antd";

const NotificationsPcPanel = ({ userType, isInPcView, onClose }) => {
  const panelRef = useRef();

  const handleOutsideClick = (event) => {
    if (panelRef.current && !panelRef.current.contains(event.target)) {
      onClose(); // Cierra el panel si se hace clic fuera
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="notifications-pc-panel-overlay">
      <div ref={panelRef} className="notifications-pc-panel">
        <section className="section-info">
          <img src={BELL_ICON_SVG} className="ap-icon" alt="" />
          <div className="ap-title">Notificaciones</div>
        </section>
        <Divider />
        <section className="section-notifications">
          {userType === 200 ? (
            <PsNotifications />
          ) : (
            <Notifications pcView={isInPcView} />
          )}
        </section>
      </div>
    </div>
  );
};

export default NotificationsPcPanel;
