import React, { useEffect, useState } from "react";
import DesktopMenu from "./DesktopMenu";
import NewTabBar from "./NewTabBar";
import { BASE_MENU_PATH } from "router/routes";

const Navigation = ({ userType, history }) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNavigate = (key) => {
    history.push(`${BASE_MENU_PATH}${key}`);
  };

  return isDesktop ? (
    <DesktopMenu userType={userType} onNavigate={handleNavigate} />
  ) : (
    <NewTabBar userType={userType} history={history} />
  );
};

export default Navigation;
