import { convertSnapshotToList } from "callbacks/list";
const LABEL_IN_PROGRESS = "En curso";
const LABEL_DONE = "Pasados";

export function getStateColor(stateKey) {
  const stateColors = {
    "state-requested": "#652d52",
    "state-in-design": "#ff69b4",
    "state-to-be-approved": "#de8080",
    "state-approved": "#7dbd07",
    "state-rejected": "#db3b37",
    "state-reschedule": "#484244",
    "state-in-progress": "#03c1e1",
    "state-done": "#fd941b",
    "state-warranty": "#cc7951",
    "state-quotation-progress": "#fecd00",
    "state-ready-execute": "#3a4d8f",
    "state-pause": "#838b95",
    "state-waiting-payment": "#61903e",
    "state-all-services": "#fabc2d",
    "state-cancel": "#ff9d76",
  };

  return stateColors[stateKey] || null;
}

export const updateList = (snapshot, dispatch) => {
  if (typeof snapshot === "object") {
    const auxList = convertSnapshotToList(snapshot.val());
    localStorage.setItem("servicesList", JSON.stringify(auxList));

    dispatch(auxList);
  }
};

export const constructorList = (servicesListInit, servicesListInProgress) => {
  const servicesListUpdated = [
    [
      LABEL_IN_PROGRESS,
      [
        ...servicesListInit,
        ...servicesListInProgress.filter(
          (service) =>
            service.state.stage === 0 ||
            service.state.stage === 1 ||
            service.state.stage === 2 ||
            service.state.stage === 4 ||
            service.state.stage === 5 ||
            service.state.stage === 7 ||
            service.state.stage === 8 ||
            service.state.stage === 9 ||
            service.state.stage === 10 ||
            service.state.stage === 11 ||
            service.state.stage === 12
        ),
      ],
    ],
    [
      LABEL_DONE,
      [
        ...servicesListInProgress.filter(
          (service) =>
            service.state.stage === 3 ||
            service.state.stage === 6 ||
            service.state.stage === 13
        ),
      ],
    ],
  ];
  return servicesListUpdated;
};

export function changeWalletViewHeight(state) {
  const isMobileView = window.matchMedia("(max-width: 768px)").matches;
  const sectionHome = document.getElementById("section-home-id");
  const walletArticle = document.getElementById("progress");

  if (!walletArticle) {
    console.error("El elemento #progress no existe.");
    return;
  }

  if (isMobileView) {
    if (state) {
      walletArticle.style.height = "400px";
    } else {
      const currentHeight = walletArticle.offsetHeight + "px";
      walletArticle.style.height = currentHeight;

      requestAnimationFrame(() => {
        walletArticle.style.height = "auto";
      });
    }
  } else {
    if (state) {
      sectionHome.style.gridTemplateColumns = "2fr 1fr 1fr";
      walletArticle.style.gridRow = "span 2";
      walletArticle.style.gridColumn = "span 2 / 2";
      walletArticle.style.height = "468px";
    } else {
      const currentHeight = walletArticle.offsetHeight + "px";
      walletArticle.style.height = currentHeight;
      walletArticle.style.gridRow = "span 1";
      walletArticle.style.gridColumn = "span 2";
      sectionHome.style.gridTemplateColumns = "repeat(3, 1fr)";

      requestAnimationFrame(() => {
        walletArticle.style.height = "auto";
      });
    }
  }
}

export const moneyFormat = ({ value = "", hideDecimal = false }) => {
  if (Number.isNaN(parseInt(value))) {
    value = 0;
  }

  return new Intl.NumberFormat("es-US", {
    style: "currency",
    currency: "USD",
    currencyDisplay: "narrowSymbol",
    ...(hideDecimal && { minimumFractionDigits: 0 }),
  }).format(value);
};
