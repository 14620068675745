import { UID_AUTH } from "../persistent-managment/key.persistent";
import { getValueByKey } from "../persistent-managment/managment.persistent";
import { currentUser } from "../services-firebase/auth";
import {
  firebaseRealTime,
  offValue,
  onValue,
  onValueByQuery,
  onValueLimit,
  onValuechildAdded,
  onValuechildChanged,
  removeDataById,
} from "../services-firebase/database";

const SERVICES_POOL = "services_pool";
const SERVICES = "services";

const KEY_ID_CUSTOMER = "id";
const KEY_SERVICE_PROVIDER = "service_provider";

const getAllServicesInit = (callback) => {
  const query = {
    orderByChild: KEY_ID_CUSTOMER,
    equalTo: getValueByKey(UID_AUTH),
  };

  return onValueByQuery(SERVICES_POOL, query, callback);
};

const getNotifications = (callback) => {
  const id = currentUser()?.uid === undefined ? "none" : currentUser()?.uid;
  return onValueLimit("notifications", id, callback);
};

const getAllServicesInProgress = (callback) => {
  const query = {
    orderByChild: KEY_ID_CUSTOMER,
    equalTo: getValueByKey(UID_AUTH),
  };

  return onValueByQuery(SERVICES, query, callback);
};

const getAllServicesInitServiceProvider = (callback) => {
  const query = {
    orderByChild: KEY_SERVICE_PROVIDER,
    equalTo: "",
  };

  return onValueByQuery(SERVICES_POOL, query, callback);
};

const getBitacle = (serviceId, callback) => {
  return onValue(`quotes/${serviceId}`, callback);
};

const getBitacleEntry = (serviceId, entryId, callback) => {
  return onValue(`quotes/${serviceId}/${entryId}`, callback);
};

const getChangedMessages = (serviceId, entryId, callback) => {
  return onValuechildChanged(
    `quotes/${serviceId}/${entryId}/messages`,
    callback
  );
};

const getNewMessages = (serviceId, entryId, callback, startAt) => {

  return onValuechildAdded(
    `quotes/${serviceId}/${entryId}/messages`,
    callback,
    startAt
  );
};

const getInitialMessages = async (serviceId, entryId, userType) => {
  const path = `quotes/${serviceId}/${entryId}/messages`;
  const messages = [];
  const query = firebaseRealTime.ref(path).orderByKey();

  const snapshot = await query.once("value");

  for (const childSnapshotKey in snapshot.val()) {
    if (snapshot.val().hasOwnProperty(childSnapshotKey)) {
      const message = snapshot.val()[childSnapshotKey];
      messages.push(message);
    }
  }
  return messages;
};

const offBitacleEntry = (serviceId, entryId, callback) => {
  return (dispatch) => {
    offValue(`quotes/${serviceId}/${entryId}`, (snapshot) => {
      dispatch({ type: "OFF_BITACLE_ENTRY" });
    });
  };
};

const getEntryData = async (serviceId, entryId) => {
  const path = `quotes/${serviceId}/${entryId}`;
  const query = firebaseRealTime.ref(path);
  const snapshot = await query.once("value");
  return snapshot.val();
};

const notificationRemoveById = (id, collectionPath) => {
  return removeDataById(collectionPath, id);
};

export {
  getAllServicesInProgress,
  getAllServicesInit,
  getAllServicesInitServiceProvider,
  getBitacle,
  getBitacleEntry,
  getChangedMessages,
  getInitialMessages,
  getNewMessages,
  getNotifications,
  notificationRemoveById,
  offBitacleEntry,
  getEntryData,
};
