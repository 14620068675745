import { TabBar } from "antd-mobile";
import React, { useState, useEffect } from "react";
import "../home/home.css";
import { BASE_MENU_PATH } from "router/routes";
import { useLocation } from "react-router-dom";
import JUSTO_PAGO_LOGO_BLANCO from "../../../assets/icons/svg/justo_pago_logo_blanco.svg";
import { tabs } from "./Tabs";

const NewTabBar = ({ history }) => {
  const location = useLocation();

  const [activeTab, setActiveTab] = useState("home");

  useEffect(() => {
    const pathKey = location.pathname.replace(BASE_MENU_PATH, "");
    if (pathKey && tabs.some((tab) => tab.key === pathKey)) {
      setActiveTab(pathKey);
    }
  }, [location.pathname]);

  const handleChange = (key) => {
    history.push(`${BASE_MENU_PATH}${key}`);
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
    handleChange(key);
  };

  return (
    <div className="tab-bar-container">
      <div className="new-tab-bar-logo">
        <img src={JUSTO_PAGO_LOGO_BLANCO} width={120} alt="justo-pago-logo" />
      </div>
      <TabBar
        activeKey={activeTab}
        onChange={handleTabChange}
        className="new-nav-bar"
      >
        {tabs.map((item) => (
          <TabBar.Item
            className={`aria-icon ${
              activeTab === item.key ? "active-tab" : ""
            }`}
            key={item.key}
            icon={item.icon(activeTab === item.key)}
            title={item.title}
          />
        ))}
      </TabBar>
    </div>
  );
};

export default NewTabBar;
