import { MoreOutlined } from "@ant-design/icons"
import { Button, Image, Popover, Spin } from "antd"
import { dateTimeFormater } from "res/utils"
import "./MediaAll.css"
import PdfPreview from "../../../../../../components/atoms/pdfPreview/PdfPreview"

const DEFAULT_FILE_IMAGE = "https://cdn4.iconfinder.com/data/icons/48-bubbles/48/12.File-512.png"
const DEFAULT_AUDIO_IMAGE = "https://firebasestorage.googleapis.com/v0/b/justo-pago.appspot.com/o/Pictures_aux_folder%2Faudio-file.png?alt=media&token=b1631aa2-9921-4aec-b7bb-a9881b0b0fa4"

export default function MediaAll({ filesList, loading }) {
  if (loading) {
    return (

      <Spin tip="Loading" size="small">
        Cargando
      </Spin>


    )
  }


  if (!filesList.length) {
    return (
      <h3>
        No hay links
      </h3>
    )
  }

  const componentByTypeFile = (file) => {
    const { type } = file
    switch (type) {
      case "pdf" || file.url.includes(".pdf"):
        return <PdfPreview
          key={file.name}
          title="Evidencia"
          pdfUrl={file.url}
        />
      case "image":
        return <Image src={file.url} alt={file.name} />

      case "audio":
        return <Image preview={false} src={DEFAULT_AUDIO_IMAGE} alt={file.name} />
      default:
        return <Image preview={false} src={DEFAULT_FILE_IMAGE} alt={file.name} />
    }
  }

  const onDownloadFile = (url) => {
    window.open(url, "_blank")
// downloadFile(url, name)
  }

  return (
    <section className="media-all-list">

      {filesList.map((item, index) => {
        const { name, date, url } = item

        return (
          <article key={index} className="media-all-item">


            {componentByTypeFile(item)}

            <div className="media-all-info">
              <header className="header-media-all-info">
                <h1 className="media-all-title">{name}</h1>

                <Popover
                  content={
                    <ul className="media-all-popover-list">
                      {/* <Button type="text">Ver en el chat</Button> */}
                      <Button onClick={() => onDownloadFile(url, name)} type="text">Descargar</Button>
                    </ul>
                  }
                  trigger="click"
                  rootClassName="media-all-options-btn-popover"
                >
                  <Button
                    icon={<MoreOutlined />}
                    className="media-all-options-btn"
                  >
                  </Button>
                </Popover>
              </header>

              <time dateTime={date} className="media-all-date">
                {dateTimeFormater({ date, dateStyle: "long" })}
              </time>
            </div>
          </article>
        )
      })}
    </section>
  )
}
