import { Avatar, Button } from 'antd';
import userIconUrl from "assets/icons/svg/user.svg";
import GoBackBtn from 'components/GoBackBtn/GoBackBtn';
import { useMemo } from 'react';
import "./Header.css";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';

export default function Header({ metadata }) {
  const avatar = useMemo(() => {
    if (metadata?.user?.register_data?.thumbnail) {
      return metadata?.user?.register_data?.thumbnail
    }

    const avatar = {
      woman:
        "https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png",
      man: "https://mir-s3-cdn-cf.behance.net/project_modules/disp/b3053232163929.567197ac6e6f5.png",
    };

    if (metadata?.user?.gender) {
      return avatar?.[metadata?.user?.gender]
    }

    return userIconUrl
  }, [metadata])

  const address = useMemo(() => {
    const addressWithoutName = metadata?.property?.nickname.replace(`${metadata?.user?.register_data?.name} - `, "").split(" - ") // clear client name & split by " - "

    let splitedAddress = structuredClone(addressWithoutName) // clone array
    const apartmentTower = addressWithoutName?.at(-1)
    const apartmentNumber = addressWithoutName?.at(-2)

    // Replace last two elements of array
    splitedAddress?.splice(-1, 1, `Trr. ${apartmentTower}`)
    splitedAddress?.splice(-2, 1, `Apto. ${apartmentNumber}`)

    return splitedAddress?.join(" - ")
  }, [metadata])

  const history = useHistory()
  const auth = useSelector((state) => state.auth)

  return (
    <header className='bitacle-phase-header'>
      <Button onClick={() => {
        if (auth.userType !== "client") {

          history.push("/all-services-added")
        } else {
          history.push("/home/menu")
        }
      }} type="primary" shape="circle" icon={< ArrowLeftOutlined />} size={"large"} />

      <section className='service-info-wrapper'>
        <Avatar
          src={avatar}
          alt="Avatar del cliente"
          size={48}
        />

        <div className='service-info'>
          <h1 className='info-name'>{metadata?.user?.register_data?.name}</h1>
          <h2 className='info-address'>
            {address}
          </h2>
        </div>
      </section>
    </header>
  )
}
