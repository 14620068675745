import { firebaseRealTime } from "./handler";

const VALUE_KEY = "value";
const CHILD_CHANGED = "child_changed";
const CHILD_ADDED = "child_added";

const onValueByQuery = (path, query, callback) => {
  const { orderByChild, equalTo } = query;
  return firebaseRealTime
    .ref()
    .child(path)
    .orderByChild(orderByChild)
    .equalTo(equalTo)
    .on(VALUE_KEY, callback);
};

const onValueLimit = (path, child, callback) =>
  firebaseRealTime
    .ref(path)
    .child(child)
    .limitToLast(100)
    .on(VALUE_KEY, callback);

const updateDocuments = async (path, object) =>
  await firebaseRealTime.ref().child(path).update(object);
const onValue = (path, callback) =>
  firebaseRealTime.ref(path).on(VALUE_KEY, callback);

const offValue = (path, callback) =>
  firebaseRealTime.ref(path).off(VALUE_KEY, callback);

const onValuechildChanged = (path, callback) => {
  const ref = firebaseRealTime.ref(path);
  ref.on(CHILD_CHANGED, callback);

  const unsubscribe = () => ref.off(CHILD_CHANGED, callback);

  return unsubscribe;
};

const removeDataById = async (collectionPath, id) => {
  try {
    await firebaseRealTime.ref(`${collectionPath}/${id}`).remove();
    console.log("Data removed successfully.");
  } catch (error) {
    console.error("Error removing data: ", error);
  }
};

const onValuechildAdded = (path, callback, startAt) => {
  const ref = firebaseRealTime.ref(path).orderByKey().startAt(`${startAt}`);

  ref.on(CHILD_ADDED, callback);

  const unsubscribe = () => ref.off(CHILD_ADDED);

  return unsubscribe;
};
export {
  firebaseRealTime,
  offValue,
  onValue,
  onValueByQuery,
  onValueLimit,
  onValuechildAdded,
  onValuechildChanged,
  removeDataById,
  updateDocuments,
};
