import { Button, ConfigProvider, Modal } from "antd";
import { ReactComponent as BellLightIcon } from "assets/icons/svg/bell-light.svg";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CHECK_QR_CODE,
  NOTIFICATIONS,
  PROFILE,
  PS_BASE_MENU_PATH
} from "router/routes";
import "./NavBar.css";

import { CommentOutlined, PlusOutlined, ScanOutlined, UserOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import OrderAdditionalService from "views/OrderRequestService/OrderAdditionalService";
import OrderRequestService from "views/OrderRequestService/OrderRequestService";
import AddNewOption from "./components/AddNewOptions";
import { Dropdown, FloatingPanel, Radio, Selector, Space } from "antd-mobile";
import NewUnexpectedForm from "../../views/newUnexpectedForm/newUnexpectedForm";

export default function NavBarForService() {
  const { id } = useParams();
  const history = useHistory();
  const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false);
  const [isNotificationFormOpen, setIsUnexpectedModalOpen] = useState(false);

  const [showRequestForm, setShowRequestForm] = useState(false);
  const [requestType, setRequestType] = useState(null);
  const [isDialogToConfirm, setIsDialogToConfirm] = useState(false);
  const [confirmMaterialModal, setConfirmMaterialModal] = useState(false);
  const [showAdditionalService, setShowAdditionalService] = useState(false);
  const [chooseOrderType, setChooseOrderType] = useState(false);

  function onHandleNewRequest(type) {

    if (type === "unexpected") {
      return setIsUnexpectedModalOpen(true)
    }
    if (type === "order") {
      setChooseOrderType(true)
      setIsAddNewModalOpen(false)

      //setShowAdditionalService(true)
    } else {
      setShowRequestForm(true)
    }

    setRequestType(type)
  }

  function onCloseRequestForm() {
    setShowRequestForm(false)
  }
  const anchors = [window.innerHeight * 0.9, window.innerHeight * 0.9];
  const anchorsOrder = [window.innerHeight * 0.7, window.innerHeight * 0.7];

  const anchorRef = useRef(null)
  return (
    <>
      <nav className="nav-bar">
        <ConfigProvider theme={{}}>
          <Button
            size="large"
            onClick={() => {
              history.push(CHECK_QR_CODE);
            }}
            data-active={history.location.pathname.includes(CHECK_QR_CODE)}
            className="nav-bar-opt"
          >
            <ScanOutlined />
          </Button>
          <Button
            size="large"
            onClick={() => {
              history.push(`/chat-phases/${id}`);
            }}
            data-active={history.location.pathname.includes(
              `/chat-phases/${id}`
            )}
            className="nav-bar-opt"
          >
            <CommentOutlined />
          </Button>

          <Button
            size="large"
            shape="circle"
            onClick={() => {
              setIsAddNewModalOpen(true);
            }}
            className="nav-bar-opt-center-ser"
          >
            <PlusOutlined />
          </Button>

          <Button
            size="large"
            onClick={() => {
              history.push(PS_BASE_MENU_PATH + NOTIFICATIONS);
            }}
            data-active={history.location.pathname.includes(NOTIFICATIONS)}
            className="nav-bar-opt"
          >
            <BellLightIcon />
          </Button>

          <Button
            size="large"
            onClick={() => {
              history.push(PS_BASE_MENU_PATH + PROFILE);
            }}
            data-active={history.location.pathname.includes(PROFILE)}
            className="nav-bar-opt"
          >
            <UserOutlined />
          </Button>
        </ConfigProvider>
      </nav>

      <Modal
        centered
        closable
        maskClosable
        footer={null}
        title={null}
        open={
          isAddNewModalOpen &&
          !showRequestForm &&
          !isDialogToConfirm &&
          !showAdditionalService &&
          !isNotificationFormOpen
        }
        onCancel={() => {
          setIsAddNewModalOpen(false);
        }}
        rootClassName="options-modal"
      >
        <AddNewOption onHandleNewRequest={onHandleNewRequest} />
      </Modal>
      <OrderRequestService
        open={showRequestForm}
        closeRequest={onCloseRequestForm}
        requestType={requestType}
        openDialogToConfirm={() => setIsDialogToConfirm(true)}
        setCloseModal={() => setIsAddNewModalOpen(false)}
        closeConfirm={() => setIsDialogToConfirm(false)}
        setOpenDateForm={() => setShowRequestForm(true)}
        isDialogToConfirm={isDialogToConfirm}
      />
      {isNotificationFormOpen && (
        <FloatingPanel
          open={isNotificationFormOpen}
          anchors={anchors}
          ref={anchorRef}
        >
          <div style={{ margin: "8px" }}>
            <NewUnexpectedForm
              onClose={() => {
                setIsUnexpectedModalOpen(false);
              }}
            />
          </div>
          <button
            onClick={() => {
              setIsUnexpectedModalOpen(false);
            }}
          >
            cerrar
          </button>
        </FloatingPanel>
      )}
      {chooseOrderType && (
        <FloatingPanel
          open={chooseOrderType}
          anchors={anchorsOrder}
          ref={anchorRef}
        >
          <div style={{ margin: "8px" }}>
            <Dropdown>
              <Dropdown.Item key="sorter" title={<h3>Escoge el pedido que deseas realizar</h3>}>
                <div style={{ padding: 12 }}>
                  <Radio.Group defaultValue="default">
                    <Space direction="vertical" block>
                      <Radio block value="default">
                        Segundo pedido
                      </Radio>
                      <Radio block value="nearest">
                        Tercer pedido
                      </Radio>
                      <Radio block value="top-rated">
                        Pedido adicional
                      </Radio>
                    </Space>
                  </Radio.Group>
                </div>
              </Dropdown.Item>
            </Dropdown>
          </div>
        </FloatingPanel>
      )}

      {showAdditionalService && (
        <OrderAdditionalService
          requestType={requestType}
          openDialogToConfirm={() => setConfirmMaterialModal(true)}
          isDialogToConfirm={confirmMaterialModal}
          closeConfirm={() => setConfirmMaterialModal(false)}
          open={showAdditionalService}
          setOpenForm={() => setShowAdditionalService(true)}
          setCloseForm={() => setShowAdditionalService(false)}
          setCloseModal={() => setIsAddNewModalOpen(false)}
        />
      )}
    </>
  );
}
