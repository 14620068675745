// import { MoreOutlined } from "@ant-design/icons";
import { Spin } from 'antd'

import { useEffect, useRef } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import './ChatBody.css'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useState } from 'react'
import { useCallback } from 'react'
import { getNewMessages } from '../../../../../../services-controller/database'

import Message from './Message'
import { DownCircleFilled } from '@ant-design/icons'


// const typesMsgCanBeEdited = ["message", "activity"]


export default function ChatBody() {
  // const chat = useSelector((state) => state.bitacle.chat, shallowEqual)
  const [chat, setChat] = useState([])  
  const chatBodyRef = useRef(null)
  const chatPhaseSelected = useSelector((state) => state.bitacle.chatPhaseSelected, shallowEqual)
  const user = useSelector(state => state.auth.user, shallowEqual)
  const userId = user.id
  const { fase, id: serviceId } = useParams()

  const newMessage = (message) => setChat((prev) => [...prev, message])
  //useDispatcherFunction(addMessage)

  const [openOptions, setOpenOptions] = useState(false)

  useEffect(() => {
    chatBodyRef.current?.scrollTo(0, chatBodyRef.current?.scrollHeight)
  }, [chat, chatPhaseSelected])
  const mappingMessages = useCallback(
    (messages) =>
      Array.isArray(messages)
        ? messages.map((item) => ({
          ...item,
          isOtherUser: item.emisor?.id !== userId,
        }))
        : { ...messages, isOtherUser: messages.emisor?.id !== userId },
    [userId]
  );



  const loadAllMessages = () => {
    // if (!messagesLoadRef.current) return;
    const addNewMessage = (snapshot) => {      
      newMessage(mappingMessages(snapshot.val()));

    };
    const unsubscribe = getNewMessages(
      serviceId,
      fase,
      addNewMessage,
      0
    );

    return unsubscribe

  }

  useEffect(() => {
    const unsubscribe = loadAllMessages();
    return () => {
      unsubscribe?.();
    };
  }, []);


  const handleClickOutside = (event) => {

    setOpenOptions(!openOptions);
  };


  const [scrollUp, seScrollUp] = useState(false);

  const handleScroll = () => {
    const container = chatBodyRef.current;

    if (container) {
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;

      // Calcula si el scroll está cerca de la parte inferior
      const isNearBottom = scrollHeight - scrollTop - clientHeight > 50; // Ajusta el umbral según sea necesario

      seScrollUp(isNearBottom);
    }
  };
  const lastMessage = chat[chat.length - 1]

  return (
    <section className='bitacle-chat-body' ref={chatBodyRef} onClick={handleClickOutside} onScroll={handleScroll} >

      {chat?.length === 0 && (
        <Spin size='large' />
      )}

      {chat?.map((item, index, array) => {        
        return (
          <Message allOpenOptions={openOptions} key={index} item={item} index={index} array={array} user={user} />
        )
      })}
      {scrollUp && <a href={"#message-" + lastMessage?.position}>

        <DownCircleFilled className='down-circle-icon' />
      </a>}
    </section>
  )
}
