import { Appbar } from "../../../components/atoms/appbar/Appbar";
//import ButtonNavbar from "../../../components/molecules/buttonNavbar/ButtonNavbar";
import { ServicesInformation } from "../../../components/organisms/information/Information";
import "./services.css";
import Navigation from "../components/Navigation";

const Services = ({ navigatorService, servicesList, history, userType }) => {
  return (
    <div className="services-container appbar-no-background">
      <div className="services-appbar-container services-user">
        <Appbar smallText="Servicios" bigText="Servicios" />
      </div>

      <div className="options-services-container">
        <ServicesInformation
          content={servicesList}
          navigatorService={navigatorService}
        />
      </div>

      <Navigation userType={userType} history={history} />
    </div>
  );
};

export default Services;
